import React,{ useState } from 'react';

import '../../../component_styles/landing/homepage/aside_section.css'

const AsideSection = () => {
    const [email, setEmail] = useState('')

    const handleChange = (e) => {
        setEmail(e.target.vaue)
    }

    return (
        <aside>
            <div className="aside-note">
                <span>Get update on success stories</span><br/>
                <span>Get directly in your email</span>
            </div>
            <div>
                <div>
                    <div className="aside-email-input-container">
                        <label htmlFor="email">Enter your email</label>
                        <input 
                            type="email" 
                            placeholder="Enter your email" 
                            id="email"
                            value={email}
                            onChange={handleChange}
                        /> 
                    </div>
                    <span className="aside-privacy-note">
                        We care about your data in our privacy policy
                    </span>
                </div>
                <div className="aside-button-container">
                    <button className="top-bar-contact-butt butt subscribe-butt">
                        {/*<a href="">*/}
                            <div className="top-bar-contact-butt-text-wrapper inner-butt">
                                Subscribe
                            </div>
                        {/*</a>*/}
                    </button>
                </div>
            </div>
        </aside>
    )
}

export default AsideSection;