import ResetPasswordFirstSlide from "./ResetPasswordFirstSlide";
import { ResetPasswordProvider } from "./ResetPasswordProvider";
import ResetPasswordSecondSlide from "./ResetPasswordSecondSlide";
import ResetPasswordThirdSlide from "./ResetPasswordThirdSlide";

import '../../../component_styles/auth/reset_password.css'

const ResetPasswordSlides = () => {

    return(
        <div className="auth-form form">

            {/*reset password provider*/}
            <ResetPasswordProvider>
                {/* reset password first slide*/}
                <ResetPasswordFirstSlide />

                {/*reset password second slide*/}
                <ResetPasswordSecondSlide />

                {/*reset password third slide*/}
                <ResetPasswordThirdSlide />
            </ResetPasswordProvider>

        </div>
    )
}

export default ResetPasswordSlides;