import { useContext, useState } from "react"

// component import
import { 
    constraints,
    LoadingIndicator, 
    TextInput 
} from "../AuthFormComponents"

// utility import
import { baseEndPoint, moveSlides } from "../../utils"

// context import
import { ResetPasswordContext } from "./ResetPasswordProvider"


const ResetPasswordFirstSlide = () => {

    const { slideIndex, setSlideIndex, setContextEmail } = useContext(ResetPasswordContext)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState('')

    const handleChange = (e) => {
        const value = e.target.value

        setEmail(value)
    }

    const checkUserExistence = async () => {
        let funcResponse = false

        try {
            const response = await baseEndPoint.post('/api/registration-detail-existence/', {
                email: email
            });

            
            if(response.data.email === "Available"){ // means that the email doesn't exist
                // display the error
                setEmailError('This account is not found!');
                // assign false to function response
                funcResponse = false
            }else if(response.data.email === "Non-available"){ // means the email exist
                // empty the email error
                setEmailError('')
                // assign true to function response
                funcResponse = true
            }

        } catch (err) {
            // display the submission error if the slideIndex is 0
            setFormError('An error occurred. Please try again.');

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    } 

    const sendPasswordResetCode = async () => {
        // show the loader
        setLoading(true)

        let funcResponse = false

        try {
            await baseEndPoint.post('/api/send-password-reset-code/', {
                email: email
            });

            funcResponse =  true

        } catch (err) {
            const status = err && err.response.status
            if (status === 400 || status === 503){
                setFormError(err.response.data.message)
            }else{
                // display the submission error if the slideIndex is 0
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    } 

    const validateInput = () => {
        let aggregateCheck = true;
        let emailError = ''
    
        // email
        if (constraints.emailRegex.test(email)) {
            emailError = '';  // Clear error
        } else {  
            aggregateCheck = false;
            emailError = 'Please enter a valid email address.';  // Set error message
        }
    
    
        // Update the form error state with all accumulated error messages
        setEmailError(emailError);
        
        // set loading to false
        setLoading(false)

        return aggregateCheck;
    };

    const handleSubmit = async () => {
        if(!loading){
            // set loading to true
            setLoading(true)

            // erase the form error if any
            setFormError('')

            // validate the input
            const inputValid = validateInput()
            if (inputValid){
                
                // request user's existence
                const userExists = await checkUserExistence()
                if (userExists){

                    // send reset code
                    const passwordCodeSent = await sendPasswordResetCode()
                    if(passwordCodeSent){
                        // set the email to the context
                        setContextEmail(email)
                        
                        // move to next slide
                        moveSlides(
                            slideIndex,
                            setSlideIndex,
                            '.reset-password-form',
                            'next'
                        )
                    }
                }
            }
        }
    }

    return(
        <div className="reset-password-form reset-password-first-slide">
            <header>
                <h2>Reset Password</h2>	
            </header>


            {/* email input */}
            <TextInput
                name="email"
                id="email"
                label="Enter email to your account"
                type="email"
                placeholder="johndoe@gmail.com"
                inputError={emailError}
                handleChange={handleChange}
                isDisabled={loading}
            />

            {/* form error */}
            {
                formError && (
                    <div className="input-error form-error">
                        {formError}
                    </div>
                )
            }

            {/* submit function */}
            <button 
                className={`green-button ${loading && 'green-button-disabled'}`}
                onClick={handleSubmit}
            >
                {loading && (<LoadingIndicator/>)}
                Submit
            </button>
        </div>
    )
}

export default ResetPasswordFirstSlide;