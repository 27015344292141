import axios from 'axios';

const moveSlides = (slideIndex, setSlideIndex, selector,direction) => {
    const slides = document.querySelectorAll(`${selector}`)

    const nextIndex = direction === "next"? slideIndex+1 : slideIndex-1
    const percent = direction === "next"? -110 : 100

    if(slides[nextIndex]){
        slides[slideIndex].style.position = "absolute" 
        slides[slideIndex].style.left = `${percent}%` 
        slides[nextIndex].style.left = "0%" 
        slides[nextIndex].style.position = "relative" 

        setSlideIndex(nextIndex)
    }
}


// Function to get the base URL
const getBaseURL = () => {
  return window.location.href.includes("localhost")
      ? "http://localhost:8000/blog"
      : "https://rakerword.pythonanywhere.com/blog";
}

// Create an Axios instance with the base URL
const baseEndPoint = axios.create({
  baseURL: getBaseURL()
});


const testData = {
    "first_name": "JoKhn",
    "last_name": "Doe",
    "email": "johndoe@example.com",
    "created_at": "2024-09-24T10:30:00Z",  // ISO 8601 format for timestamps
    "last_login": "2024-09-24T14:45:00Z",
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
    "timezone": "America/New_York", // Timezone example
    "records": {
      "campaigns": [
        {
            "title": "Clean Water for All",
            "target_amount": "10000",
            "amount_raised": "8500",
            "no_of_contributors": "150",
            "registration_status": true,
            "image_url": "https://example.com/images/clean_water.jpg",
            "date_started": "2024-09-25T14:16:52.473190+00:00",
            "date_ended": "2024-09-25T14:16:52.473190+00:00",
            "description": "Last year, Sarah's village in Kenya was struggling with access to clean water. Thanks to generous donations, a new well was built right in the heart of her community. Today, over 500 families have access to fresh, safe drinking water, drastically reducing illness and improving daily life. Sarah no longer has to walk miles each day to collect water, allowing her to attend school regularly and dream of Last year, Sarah's village in Kenya was struggling with access to clean water. Thanks to generous donations, a new well was built right in the heart of her community. Today, over 500 families have access to fresh, safe drinking water, drastically reducing illness and improving daily life. Sarah no longer has to walk miles each day to collect water, allowing her to attend school regularly and dream of."
        },
        {
            "title": "School Supplies Drive",
            "target_amount": "5000",
            "amount_raised": "3000",
            "no_of_contributors": "80",
            "registration_status": true,
            "image_url": "https://example.com/images/school_supplies.jpg",
            "date_started": "2024-09-25T14:16:52.473190+00:00",
            "date_ended": "2024-09-25T14:16:52.473190+00:00",
            "description": "Helping children from low-income families get access to essential school supplies for a better education."
        },
        {
            "title": "Hunger Relief Program",
            "target_amount": "20000",
            "amount_raised": "17500",
            "no_of_contributors": "320",
            "registration_status": false,
            "image_url": "https://example.com/images/hunger_relief.jpg",
            "date_started": "2024-09-25T14:16:52.473190+00:00",
            "date_ended": "2024-09-25T14:16:52.473190+00:00",
            "description": "Providing meals and support to families struggling with hunger and food insecurity in remote areas."
        }
      ],
      "donation_history": [
          {
            "date_of_donation": "2024-09-25T14:16:52.473190+00:00",
            "amount_donated": "200",
            "donation_cause": "Clean Water for All",
            "payment_method": "Credit Card",
            "payment_status": "Completed"
          },
          {
            "date_of_donation": "2024-09-25T10:36:33.147920",
            "amount_donated": "300",
            "donation_cause": "School Supplies Drive",
            "payment_method": "PayPal",
            "payment_status": "Failed"
          },
          {
            "date_of_donation": "2024-09-25T10:36:33.147920",
            "amount_donated": "700",
            "donation_cause": "Hunger Relief Program",
            "payment_method": "Bank Transfer",
            "payment_status": "Pending"
          }
      ],
      "impact_updates": [
        {
          "image_url": "https://example.com/image1.jpg",
          "description": "Last year, Sarah's village in Kenya was struggling with access to clean water. Thanks to generous donations, a new well was built right in the heart of her community. Today, over 500 families have access to fresh, safe drinking water, drastically reducing illness and improving daily life. Sarah no longer has to walk miles each day to collect water, allowing her to attend school regularly and dream of Last year, Sarah's village in Kenya was struggling with access to clean water. Thanks to generous donations, a new well was built right in the heart of her community. Today, over 500 families have access to fresh, safe drinking water, drastically reducing illness and improving daily life. Sarah no longer has to walk miles each day to collect water, allowing her to attend school regularly and dream of."
        },
        {
          "image_url": "https://example.com/image2.jpg",
          "description": "The school supplies have been distributed to 300 students in need."
        },
        {
          "image_url": "https://example.com/image3.jpg",
          "description": "We successfully delivered meals to 1,000 families through the Hunger Relief Program."
        }
      ]
    }
}

const dateOptions = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
};

const hypenDateFormat = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');  // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
   
const flexifyColumns = ({selector,gap,childMinWidth}) => {
  const parentContainer = document.querySelector(`${selector}`);
  const count = parentContainer.childElementCount
  const childWidth = parentContainer.firstElementChild.offsetWidth
  const length = (childWidth * count) + ((gap-1) * count)
  if (length > window.innerWidth){
      parentContainer.style.gridTemplateColumns = `repeat(auto-fit, minmax(${childMinWidth}, 1fr))`
  }
}

export{
    moveSlides,
    baseEndPoint,
    testData,
    dateOptions,
    hypenDateFormat,
    flexifyColumns,
}