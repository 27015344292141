import React from 'react';
import { Link } from 'react-router-dom';


import { GreenHeart, 
    Scroll1Active, 
    Scroll1Inactive, 
    Scroll2Active, 
    Scroll2Inactive 
} from '../SVGDump';

// style import
import '../../../component_styles/landing/homepage/donation_section.css'

const DonationSection = () => {

    const donationEntries = [
        {
            "tag":"Health",
            "title": "Brain Tumor Surgery Recovery Fund",
            "goal": "12000",
            "collected": "7490",
            "remaining":"4510",
            "image_url": "https://images.gofundme.com/nduucMvWrKIux7stjHCxuq6nDp0=/720x405/https://d2g8igdw686xgo.cloudfront.net/75745173_1696354978316956_r.jpeg",
        },
        {
            "tag":"Care",
            "title": "Ivan Rubanik Family Support",
            "goal": "45000",
            "collected": "13296",
            "remaining":"31704",
            "image_url": "https://images.gofundme.com/GdTNwi_7eKjD8gpJBkGY_hkr2kQ=/720x405/https://d2g8igdw686xgo.cloudfront.net/77243277_170334899310753_r.jpeg"
        },
        {
            "tag":"Health",
            "title": "Baby Ollie Roche",
            "goal": "60000",
            "collected": "35073",
            "remaining":"24927",
            "image_url": "https://images.gofundme.com/Bn8TcvG7OyfzOeUrnqqRzCMxpy4=/720x405/https://d2g8igdw686xgo.cloudfront.net/77274667_1703352442308752_r.png"
        },
        {
            "tag":"Health",
            "title": "Fighting for Finn",
            "goal": "50,000",
            "collected": "70549",
            "remaining":"None",
            "image_url": "https://images.gofundme.com/YfuVy4LxVTMLcFtFjfPnnnsMSBk=/720x405/https://d2g8igdw686xgo.cloudfront.net/77183313_1702910935192319_r.jpeg",
        },
        {
            "tag":"Health",
            "title": "Justice for Gor Adamyan",
            "goal": "80000",
            "collected": "67965",
            "remaining":"12035",
            "image_url":"https://images.gofundme.com/dP0c8Hi_cCZAtUCeo443dSEP3u8=/720x405/https://d2g8igdw686xgo.cloudfront.net/77179241_1702869267833164_r.jpeg"
        },
        {
            "tag":"Health",
            "title": "John Frank’s Journey",
            "goal": "10000",
            "collected": "10435",
            "remaining":"None",
            "image_url": "https://images.gofundme.com/8VGu7vGw8SBE81gUCzWgWZ5AIwg=/720x405/https://d2g8igdw686xgo.cloudfront.net/77167953_1702821344737422_r.jpeg"
        },
        {
            "tag":"Health",
            "title": "Help Cole Beat Leukemia",
            "goal": "25000",
            "collected": "15970",
            "remaining":"9030",
            "image_url": "https://images.gofundme.com/KgmyZiv-BemR38RpnnZGaX0I1lo=/720x405/https://d2g8igdw686xgo.cloudfront.net/77188437_1702922863227938_r.jpeg"
        },
        {
            "tag":"Care",
            "title": "In Memory of Abi Elizabeth Munro",
            "goal": "12000",
            "collected": "8382",
            "remaining":"3618",
            "image_url":"https://images.gofundme.com/Mw_eRJttoW748qXZGZCQ9lSAAjE=/720x405/https://d2g8igdw686xgo.cloudfront.net/76940667_1701768952343851_r.jpeg"
        },
        {
            "tag":"Health",
            "title": "Support the Bilanski’s During Max’s Medical Care",
            "goal": "10000",
            "collected": "38843",
            "remaining":"none",
            "image_url": "https://images.gofundme.com/SQLq0ohqNLimW1_nOA8OMAdGlHw=/720x405/https://d2g8igdw686xgo.cloudfront.net/77136367_1702652931793538_r.jpeg"
        },
        {
            "tag":"Health",
            "title": "STEPHANIES FIGHT TO SURVIVE RARE CANCER",
            "goal": "20000",
            "collected": "7950",
            "remaining":"12050",
            "image_url":"https://images.gofundme.com/gvymJzwjdwriqMeqSjCgQl2RCV4=/720x405/https://d2g8igdw686xgo.cloudfront.net/77179679_1702869773861948_r.jpeg"
        },
        {
            "tag":"Care",
            "title": "In Memory of Charles Officer",
            "goal": "100000",
            "collected": "58605",
            "remaining":"41395",
            "image_url":"https://images.gofundme.com/d3Vt1mMTBFTBno4mdi0rGSOj10U=/720x405/https://d2g8igdw686xgo.cloudfront.net/76953115_170181779987975_r.jpeg"
        },
    ]

    const formatAmount = (amount) => {    
        // Use regular expression to add commas for thousands
        return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <section className="donate-section">
            <div className="smaller-note-wrapper">
                <div className="smaller-note">DONATE</div>
            </div>
            <div className="donation-heading heading-text">
                Your Help is Needed
            </div>
            <div className="scroll-note">
                Slide to see more
            </div>
            <div className="donation-scroll ds">
                <div className="donation-scroll1 ds1">
                    <span className="scroll1active s1a">
                        <Scroll1Active />
                    </span>
                    <span className="scroll1inactive s1ia">
                        <Scroll1Inactive />
                    </span>
                </div>
                <div className="donation-scroll2 ds2">
                    <span className="scroll2active s2a">
                        <Scroll2Active/>
                    </span>
                    <span className="scroll2inactive s2ia">
                        <Scroll2Inactive/>
                    </span>
                </div>
            </div>
            {/*donation slide*/}
            <div className="donation-slide" id="beneficiaries">
                {
                    donationEntries.map((entry,index) => (
                        <div key={index} className={`donation${index+1}`}>
                            <div className="campaign-image-wrapper">
                                <img
                                    src={entry.image_url}
                                    alt="donation campaign thumbnail"
                                />
                            </div>
                            <div className="donation-detail">
                                <div className="tag">{entry.tag}</div>
                                <div className="case">{entry.title}</div>
                                <div className="progress-bar">
                                    <div className="bar">
                                        <div 
                                            className="inner-bar"
                                            style={{width:`${(100*entry.collected)/entry.goal}%`}}
                                        ></div>
                                    </div>
                                </div>
                                <div className="mark">
                                    <div>
                                        <span>Goal</span><br/>
                                        <span>{`$${formatAmount(entry.goal)}`}</span>
                                    </div>
                                    <div>
                                        <span>Collected</span><br/>
                                        <span>{`$${formatAmount(entry.collected)}`}</span>
                                    </div>
                                    <div>
                                        <span>Remaining</span><br/>
                                        <span>
                                            {
                                                Number(entry.remaining)?
                                                `$${formatAmount(entry.remaining)}`:entry.remaining
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="donate-sect-button-wrapper">
                                    <button className="donate-button butt">
                                        <Link to="/dashboard">
                                            <div className="inner-butt">
                                                <span>Donate</span>
                                                <span>
                                                   <GreenHeart /> 
                                                </span>
                                            </div>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default DonationSection;