import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';

import '../../component_styles/dashboard/expired_session.css';

const SessionExpiredPopup = () => {	
	return ReactDOM.createPortal(
		<div className="expired-session-overlay">
            <div className="expired-session-display">
                <p>
                    Your session is invalid!
                </p>
                <div>
                    <Link to="/signin">
                        Sign in
                    </Link>
                </div>
            </div>
        </div>,
		document.body
	);
};

export default SessionExpiredPopup