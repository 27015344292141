import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// style import
import '../../component_styles/dashboard/dashboard.css';

// component import
import SideBar from './Sidebar';
import MainBar from './MainBar';

// context provider import
import { MenuProvider } from './MenuStateProvider';
import { QuickAccessIcon } from './SvgDump';


const DashboardBaseTemplate = () => {

    useEffect(()=>{
        document.documentElement.style.backgroundColor = "#f5f7f9ff"

        /*Change the content of the title bar*/
        document.title = 'Dashboard'
    },[])
    
    return(
        <div className="gray-db-backdrop-wrapper">
            <div className="gray-db-backdrop">
                <MenuProvider>
                    {/*sidebar*/}
                    <SideBar/>

                    {/*mainbar*/}
                    <MainBar/>

                    {/* quick access float */}
                    <div className="right-suspended-quick-access">
                        {/*icon*/}
                        <QuickAccessIcon/>
                        <ul>
                            <li>
                                <Link to="/">
                                    Visit homepage
                                </Link>
                            </li>
                            <li>
                                <a href="mailto:support@americannativeheartfoundation.com">
                                    Contact support
                                </a>
                            </li>
                        </ul>
                    </div>
                </MenuProvider>
            </div>
        </div>
    )
}

export default DashboardBaseTemplate;