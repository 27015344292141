import { Link } from "react-router-dom";

import '../../../component_styles/landing/homepage/cta.css';

const LilCtaSection = () => (
    <section className="lil-sec-cta">
        <div className="lil-sec-cta-flex1">
            <div className="heading-text">Join us in making a difference - every contribution counts.</div>
            <button className="donate-button butt" >
                <Link to="/dashboard">
                    <div className="inner-butt">
                        <span>Join now</span>
                    </div>
                </Link>
            </button>
        </div>
        <div className="lil-sec-cta-flex2">
            <div>
                <img
                    src="https://images.unsplash.com/photo-1593113598332-cd288d649433?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2hhcml0eXxlbnwwfHwwfHx8MA%3D%3D"
                    alt="Sharing donation items from a truck"
                />
            </div>
        </div>
    </section>
) 

export default LilCtaSection