import { Link } from "react-router-dom";


import '../../../component_styles/landing/homepage/hero_section2.css';

import { WhiteHeart } from "../SVGDump";

const HeroSection2 = () => (
    <section className="hero-section2">
        <img
            src="https://res.cloudinary.com/derzcse6f/image/upload/v1727550186/hero2_sl1ix6.png"
            alt="Coworkers smiling at each other"
        />
        <div className="hero-text-wrapper">
            <div className="hero-note heading-text">
                Join us in making a difference -
                every contribution counts
            </div>
            <p>
                Spreading love in a time where love is hard to comeby 
                makes a lot of difference. Be that Difference
            </p>
            <div className="hero-buttons">
                <button className="donate-button butt">
                    <Link to="/dashboard">
                        <div className="inner-butt">
                            <span>
                                <WhiteHeart />                                
                            </span>
                            <span>Donate And Help</span>
                        </div>
                    </Link>
                </button>
                <button className="learn-more-button butt">
                    <Link to="/about">
                        <div className="inner-butt">
                            <span className="learn-more-text">Learn More</span>
                        </div>
                    </Link>
                </button>
            </div>
        </div>
    </section>
)

export default HeroSection2