import { useEffect, useState } from "react"

// style import
import '../../../component_styles/dashboard/dashboard_pane/donate_pane.css';

// component import
import { 
    CustomTextArea,
    LoadingIndicator,
} from "../../auth/AuthFormComponents"
import { 
    RequestFormEasyIcon, 
    RequestFormPowerfulIcon,
    RequestFormTruestedIcon, 
} from "../SvgDump"

const DonatePane = () => {

    const [formData, setFormData] = useState({
        'donation_amount': '',
        'donation_cause': '',
        'selected_payment_method':'Credit/Debit Card'
    })
    const [formInputError, setFormInputError] = useState({
        'donation_amount':'',
        'donation_cause':''
    })
    const [paymentMethodState, setPaymentMethodState] = useState({
        'creditDebit': true,
        'paypal': false,
        'crypto': false,
    })

    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const value = e.target.value

        setFormData((prev)=>({
            ...prev,
            [e.target.id]: value 
        }))
    }

    const handlePaymentMethodChange = (e) => {
        // set the display
        setPaymentMethodState((prevState) => {
            // Create a new state object where all keys are set to false
            const updatedState = Object.keys(prevState).reduce((acc, method) => {
                acc[method] = false;
                return acc;
            }, {});
    
            // Set the selected payment method to true
            updatedState[e.target.id] = true;
    
            return updatedState;
        });

        setFormData((prev)=>({
            ...prev,
            'selected_payment_method': e.target.value
        }))
    }

    const validateInput = () => {
        const {
            donation_amount,
            donation_cause,
        }  = formData
        // trimmed values
        const amount = donation_amount.trim()
        const cause = donation_cause.trim()
        
        const proxyErrorObject = {}
        let aggregateCheck = true
    
        
        // amount
        if (Number(amount) > 3) {
            proxyErrorObject.donation_amount = '';  // Clear error
        } else {
            aggregateCheck = false;
            proxyErrorObject.donation_amount = 'Enter a valid amount.';  // Set error message
        }

        // cause
        if (cause.length > 10) {
            proxyErrorObject.donation_cause = '';  // Clear error
        } else {
            aggregateCheck = false;
            proxyErrorObject.donation_cause = 'Please specify the purpose or recipient for your donation to ensure it reaches the intended cause. Thank you for your support.';  // Set error message
        }
    
        // set the error state
        setFormInputError((prev)=>({
            ...prev,
            ...proxyErrorObject
        }))
    
        setLoading(false)
    
        return aggregateCheck
    }

    const handleSubmit = () => {
        if(!loading){
            // set loading to true
            setLoading(true)

            const testPassed = validateInput()
            if (testPassed){
                const {
                    donation_amount,
                    donation_cause,
                    selected_payment_method
                }  = formData

                let financeTeamMail="FinanceTeam@americannativeheartfoundation.com";
                const link=document.createElement("a")
                link["href"]=`mailto:${financeTeamMail}?subject=Pledge of Support for American Native Heart Foundation&body=I am writing to express my pledge of support for the American Native Heart Foundation. I am committed to contributing $${donation_amount} to aid in your noble cause. For the payment process, I would prefer to use ${selected_payment_method}. ${donation_cause}${donation_cause[donation_cause-1]!=="."?".":""}`
                link.click()
            }
        }
    }

    useEffect(()=>{
        /*Change the content of the title bar*/
        document.title = 'Donate now'

        /*replace the current history*/
        window.history.replaceState(null, null, "/donate-now");
    },[])


    return(
        <div className="donate-pane">
            <div className="fundraiser-form donate-form">
                <div className="fundraiser-main">
                    <h2>American Native Heart Foundation</h2>

                    <p>
                        You are donating and supporting those in dire need of your help
                    </p>

                    {/* donation amount wrapper */}
                    <div className="input-wrapper">
                        <label htmlFor="donation_amount">Enter your donation amount</label>
                        <div className="input-amount-wrapper">
                            <div className="ty">
                                <span>$</span>
                                <span>usd</span>
                            </div>
                            <input 
                                type="text"
                                name="donation_amount"
                                id="donation_amount"
                                value={formData.donation_amount}
                                onChange={handleChange}
                                disabled={loading}
                            />
                        </div>
                        {/* donation amount error*/}
                        {
                            formInputError.donation_amount && (
                                <div className="input-error">
                                    {formInputError.donation_amount}
                                </div>
                            )
                        }
                    </div>

                    {/*payment method*/}
                    <div className="payment-method">
                        {/* Here contains payment method */}
                        <h4>Pick a payment method</h4>

                        <div className="radio-wrapper">
                            {/* debit/credit card radio input wrapper */}
                            <div className="custom-radio-option">
                                {/* radio input*/}
                                <input
                                    className="custom-radio"
                                    type="radio" 
                                    id="creditDebit" 
                                    name="paymentMethod" 
                                    value="Credit/Debit Card" 
                                    checked={paymentMethodState.creditDebit}
                                    onChange={handlePaymentMethodChange}
                                    disabled={loading}
                                />
                                {/* label */}
                                <label htmlFor="creditDebit">
                                    Credit or Debit Card
                                </label>
                            </div>
                        
                            {/* paypal radio input wrapper */}
                            <div className="custom-radio-option">
                                <input 
                                    className="custom-radio"
                                    type="radio" 
                                    id="paypal" 
                                    name="paymentMethod" 
                                    value="Paypal service"
                                    checked={paymentMethodState.paypal}
                                    onChange={handlePaymentMethodChange}
                                    disabled={loading}
                                />
                                <label htmlFor="paypal">
                                    PayPal Services
                                </label>
                            </div>
                        
                            {/* crypto radio input wrapper*/}
                            <div className="custom-radio-option">
                                <input 
                                    className="custom-radio"
                                    type="radio" 
                                    id="crypto" 
                                    name="paymentMethod" 
                                    value="Bitcoin/Usdt"
                                    checked={paymentMethodState.crypto}
                                    onChange={handlePaymentMethodChange}
                                    disabled={loading}
                                />
                                <label htmlFor="crypto">
                                    Bitcoin/USDT
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* reason */}
                    <div className="reason">
                        <h4>
                            For which course are you making your donation?
                        </h4>
                        <div className="required-indicator"><span>?</span><span> Required</span></div>
                        <CustomTextArea
                            name="donation_cause"
                            id="donation_cause"
                            placeholder="Please briefly specify the course you're donating for..."
                            maxlength="500"
                            inputError={formInputError.donation_cause}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                    </div>
                    {/* tip */}
                    <div className="tip">
                        <h4>
                            Tip
                        </h4>
                        <p>
                            AmericanHeartFoundation has a 0% platform fee for organizers. 
                            AmericanHeartFoundation will continue offering its services thanks to donors.
                        </p>
                        <div className="tip-summary">
                            <div>Total Due</div>
                            <div className="summary-amount">
                                {
                                    formData.donation_amount?`$${parseFloat(formData.donation_amount).toLocaleString('en-US')}`:"$00"
                                }
                            </div>
                        </div>
                    </div>
                    {/* notice */}
                    <div className="notice">
                        <h4>Important notice: Please read</h4>
                        <p>
                            To further enhance the security of your financial transactions and protect sensitive information, 
                            we kindly request that you reach out directly to our dedicated Financial Team. Our team of experts is 
                            available to guide you through the most secure and confidential channels for your donation.
                            You can also append your full name to the email if you wish to.<br/>
                            <span>
                                Clicking on the Donate now Button does that.
                            </span>
                        </p>
                    </div>
                    {/* submit button */}
                    <div className="fundraiser-submit-button-wrapper">
                        <button 
                            style = {
                                loading ? {
                                    backgroundColor: "#ccccccff",
                                    color: "var(--bs-gray-500)",
                                    cursor: "not-allowed",
                                    position: "relative"
                                }:{}
                            }
                            onClick={handleSubmit}
                        >
                            {loading && (<LoadingIndicator/>)}
                            Submit
                        </button>
                    </div>
                </div>
                <div className="fundraiser-aside">
                    <h2>
                        Your easy, powerful, and trusted home for help
                    </h2>
                    <div className="perks">
                        <div>
                            <div>
                                <RequestFormEasyIcon />
                            </div>
                            <div>
                                <span className="perk-head">
                                    Easy
                                </span><br/>
                                <span className="perk-note">
                                    Donate quickly and easily
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <RequestFormPowerfulIcon />
                            </div>
                            <div>
                                <span className="perk-head">Powerful</span><br/>
                                <span className="perk-note">Send help right to the people and causes you care about.</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <RequestFormTruestedIcon />
                            </div>
                            <div>
                                <span className="perk-head">Trusted</span><br/>
                                <span className="perk-note">Your donation is protected by the AmericanNativeHeart Giving Guarantee.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DonatePane;