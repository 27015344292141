import React from "react"
import { Link } from "react-router-dom"

import '../../../component_styles/landing/homepage/hero_section.css'

import { 
    WhiteHeart
} from '../SVGDump';


const HeroSection = () => (
    <section className="hero-section">
        <div className="hero-image-wrapper">
            <div className="hero-image-inner-wrapper">
                <div className="hero-image"></div>
            </div>
        </div>
        <div className="hero-text-wrapper">
            <div 
                className="hero-note heading-text"
            >
                Bridging hope for vulnerable members of our community
            </div>
            <div className="hero-buttons">
                <button className="donate-button butt">
                    <Link to="/dashboard">
                        <div className="inner-butt">
                            <span>
                                <WhiteHeart />                        
                            </span>
                            <span>Donate And Help</span>
                        </div>
                    </Link>
                </button>
                <button className="learn-more-button butt">
                    <Link to="/about">
                        <div className="inner-butt">
                            <span className="learn-more-text">
                                Learn More
                            </span>
                        </div>
                    </Link>
                </button>
            </div>
        </div>
    </section>
)

export default HeroSection