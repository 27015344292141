
import '../../../component_styles/landing/homepage/founders_section.css'

const FoundersSection = () => {
    const foundersList = [
        {
            'name': 'Barack Obama',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657507/Barrack_Obama_gy7ih2.jpg"
        },
        {
            'name': 'Julia Brownley',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728898666/julia_brownley_ryg1vc.jpg"
        },
        {
            'name': 'Governor Tim Walz',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657676/Governor_Tim_Walz_v4anf5.jpg"
        },
        {
            'name': 'Tina Liebling',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657955/tina-liebling_dnf7ju.jpg"
        },
        {
            'name': 'Elizabeth Warren',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658032/elizabeth-warren_sbreo1.jpg"
        },
        {
            'name': 'Majid M. Padellan',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658106/MaJid_M._Padellan_mxwsrv.jpg"
        },
        {
            'name': 'Mark Takano',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728898882/mark_takano_u4sfwy.jpg"
        },
        {
            'name': 'Ed krassenstein and Brian krassenstein',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658231/Ed_krassenstein_and_Brian_krassenstein_vtodpl.jpg"
        },
        {
            'name': 'Diana Degette',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728899039/diana_degette_haik5n.jpg"
        },
    ]

    return(
        <section className="founders">
            <div className="smaller-note-wrapper">
                <div className="smaller-note">MEET THE FOUNDERS</div>
            </div>
            <div className="service-heading heading-text">
                Great founders
            </div>
            <div className="founder-flex-wrapper">
                <div className="founder-image-wrapper">
                    <div className="founder-image">
                        <img
                            src="https://res.cloudinary.com/derzcse6f/image/upload/v1727657283/John-Martins_mjqqcq.png"
                            alt="John martin"
                        />
                    </div>
                </div>
                <div>
                    <div className="founder-note-wrapper">
                        <span className="founder-note">
                            At the heart of our foundation lies a deep commitment to those facing unique hurdles, 
                            including individuals with disabilities, seniors navigating the complexities of aging, and those grappling 
                            with financial hardships that impact their ability to meet essential needs like bills.
                        </span><br/>
                        <span>- John Martins (CEO)</span>
                    </div>

                    {/*other co-founders*/}
                    <div className="slide-heading">
                        FOUNDERS
                    </div>
                    <div className="scroll-note">Slide to see more</div>
                    <div className="founder-slideshow">
                        <div className="founder-slideshow-wrapper">
                            {
                                foundersList.map((entry,index)=>(
                                    <div key={index} className="slides">
                                        <div className="slide-photo">
                                            <img
                                                src={entry.image_url}
                                                alt={entry.name}
                                            />
                                        </div>
                                        <div className="slide-name">{entry.name}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FoundersSection;