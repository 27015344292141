import { useEffect, useState } from "react";
import { LoadingPlaceholder } from "../../add_ons/AddOns";
import { baseEndPoint } from "../../utils";


import '../../../component_styles/landing/blog.css';
import NewsGrid from "../news/NewsGrid";

const HomepageBlogSnippet = () => {
 
    const [data, setData] = useState(null)
    const [dataError, setDataError] = useState(false)




    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await baseEndPoint.get('/api/latest-news/');
    
                setData(response.data)
            } catch (err) {
                setDataError(true)
            } 
        }

        fetchData()
    },[])

    
    return(
        <section className="homepage-blog-snippet blog-section">
            <h2>Top Stories</h2>

            {/* doubled underline */}
            <div className="doubled-underline">
                <div></div>
                <div></div>
            </div>

            {/*loading placeholder*/}
            {
                ((data === null) && (dataError===false)) && (
                    <LoadingPlaceholder/>
                )
            }

            {/* story listing */}
            {
                data && (
                    <NewsGrid data={data}/>
                )
            }

            {/*error display*/}
            {
                dataError && (
                    <div 
                        className="error-note"
                        style={{marginTop: "20px"}}
                    >
                        News articles failed to load
                    </div>
                )
            }
        </section>
    )
}

export default HomepageBlogSnippet;