// Array of American names
const americanNames = [
    'James', 'Emma', 'Liam', 'Olivia', 'Noah', 'Ava', 'Isabella', 'Sophia', 'Jackson', 'Oliver',
    'Lucas', 'Aiden', 'Caden', 'Mia', 'Charlotte', 'Harper', 'Evelyn', 'Abigail', 'Amelia', 'Elijah',
    'Alexander', 'Ethan', 'Benjamin', 'Grace', 'Scarlett', 'Sofia', 'Aria', 'Chloe', 'Lily', 'Zoe',
    'Mason', 'Logan', 'Emma', 'Avery', 'Layla', 'Aubrey', 'Camila', 'Luna', 'Aurora', 'Hazel', 'Zara',
    'Leo', 'Michael', 'Ella', 'Claire', 'Nora', 'Scarlet', 'Penelope', 'Lily', 'Aria', 'Aurora',
    'Jackson', 'Emma', 'Olivia', 'Sophia', 'Liam', 'Mia', 'Amelia', 'Evelyn', 'Harper', 'Ava',
    'Isabella', 'Emily', 'Madison', 'Abigail', 'Ella', 'Avery', 'Scarlett', 'Grace', 'Chloe', 'Lily',
    'Zoe', 'Sofia', 'Aria', 'Luna', 'Hazel', 'Eleanor', 'Isla', 'Clara', 'Charlotte', 'Rose', 'Penelope',
    'Lucy', 'Aubrey', 'Maya', 'Stella', 'Zara', 'Leah', 'Aurora', 'Violet', 'Savannah', 'Audrey', 'Brooklyn',
    'Bella', 'Ellie', 'Paisley', 'Naomi', 'Skylar', 'Aria', 'Liam', 'Olivia', 'Emma', 'Sophia', 'Isabella'
];
const americanNames2 = [
    "Samuel", "Aubrey", "David", "Zoe", "Joseph", "Layla", "Carter", "Madison", "Owen", "Penelope",
    "Wyatt", "Nora", "John", "Camila", "Jack", "Paisley", "Luke", "Skylar", "Jayden", "Sofia",
    "Dylan", "Luna", "Grayson", "Brooklyn", "Levi", "Everly", "Isaac", "Aurora", "Gabriel", "Hannah",
    "Julian", "Scarlet", "Anthony", "Nevaeh", "Leo", "Mila", "Jaxon", "Eleanor", "Lincoln", "Aaliyah",
    "Christopher", "Eliana", "Joshua", "Ellie", "Andrew", "Stella", "Caleb", "Hailey", "Nathan", "Kaylee",
    "Ryan", "Lillian", "Aaron", "Addison", "Adrian", "Zara", "Liam", "Emma", "Noah", "Olivia", "William", 
    "Benjamin", "Mia", "Mason", "Amelia", "Elijah", "Harper", "Oliver", "Evelyn", "Jacob", "Abigail",
    "Lucas", "Aria", "Michael", "Emily", "Alexander", "Charlotte", "Ethan", "Scarlett", "Daniel", "Grace",
    "Henry", "Chloe", "Jackson", "Avery", "Sebastian", "Lily", "Aiden", "Ella", "Matthew", "Riley",
    "Ava", "James", "Isabella", "Logan", "Sophia",
    
    // Add more names as needed
];


// Array of American counties
const americanCounties = [
    'Los Angeles', 'Cook', 'Harris', 'Maricopa', 'San Diego',
    'Orange', 'Miami-Dade', 'Dallas', 'Riverside', 'San Bernardino',
    'King', 'Clark', 'Queens', 'Tarrant', 'Broward',
    'Santa Clara', 'Alameda', 'Sacramento', 'Contra Costa', 'Philadelphia',
    'Kings', 'Hillsborough', 'Hennepin', 'Orange', 'Cuyahoga',
    'Denver', 'Marion', 'Travis', 'Bexar', 'San Francisco',
    'St. Louis', 'Middlesex', 'Fulton', 'Allegheny', 'Oakland',
    'Milwaukee', 'Duval', 'Franklin', 'Fairfax', 'Memphis',
    'Shelby', 'Honolulu', 'Wayne', 'Baltimore', 'Fresno',
    'Wake', 'New York', 'Erie', 'Suffolk', 'Salt Lake',
    'Allegheny', 'Orange', 'Jefferson', 'Hartford', 'New Haven',
    'Fairfield', 'Litchfield', 'Middlesex', 'Bergen', 'Essex',
    'Hudson', 'Union', 'Middlesex', 'Monmouth', 'Ocean',
    'Somerset', 'Morris', 'Burlington', 'Camden', 'Gloucester',
    'Mercer', 'Hunterdon', 'Passaic', 'Sussex', 'Warren',
    'Burlington', 'Camden', 'Gloucester', 'Mercer', 'Hunterdon',
    'Passaic', 'Sussex', 'Warren', 'Monroe', 'Wayne',
    'Macomb', 'Livingston', 'St. Clair', 'Lapeer', 'Lenawee',
    'Monroe', 'La Porte', 'Bristol', 'Plymouth', 'Barnstable',
    'Martha\'s Vineyard'
];

const americanCounties2 = [
    "New York", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego",
    "Dallas", "San Jose", "Austin", "Jacksonville", "San Francisco", "Indianapolis", "Columbus", "Fort Worth",
    "Charlotte", "Seattle", "Denver", "El Paso", "Detroit", "Washington", "Boston", "Memphis", "Nashville",
    "Portland", "Oklahoma City", "Las Vegas", "Baltimore", "Louisville", "Milwaukee", "Albuquerque", "Tucson",
    "Fresno", "Sacramento", "Kansas City", "Long Beach", "Mesa", "Atlanta", "Colorado Springs", "Virginia Beach",
    "Raleigh", "Omaha", "Miami", "Oakland", "Minneapolis", "Tulsa", "Wichita", "New Orleans", "Arlington",
    "Cleveland", "Tampa", "Bakersfield", "Aurora", "Anaheim", "Honolulu", "Santa Ana", "Riverside", "Corpus Christi",
    "Lexington", "Stockton", "St. Louis", "Saint Paul", "Henderson", "Pittsburgh", "Orlando", "Cincinnati", "Anchorage",
    "Greensboro", "Plano", "Newark", "Lincoln", "Toledo", "Fort Wayne", "St. Petersburg", "Chula Vista", "Jersey City",
    "Chandler", "Buffalo", "Durham", "St. Paul", "St. Petersburg", "Laredo", "Madison", "Lubbock", "Garland", "Akron",
    "Rochester", "Arlington", "Modesto", "Fremont", "Glendale", "Montgomery"
    // Add more cities as needed
];

const testimonyQuote = [
    "American Native Heart Foundation stands as a beacon of hope, united by a mission of compassion. Their dedication to positive change has brightened countless lives, showing us the power of giving.",
    "At the core of American Native Heart Foundation lies the heart of kindness. Every contribution they receive sparks a ripple of generosity, spreading hope and transformation across communities.",
    "With every act of generosity, American Native Heart Foundation creates a wave of hope. Their unwavering commitment to giving back serves as an inspiration to us all, reminding us that together, we can change the world.",
    "American Heart Nation embodies humanity at its finest. Their work transcends boundaries, weaving a global fabric of kindness and generosity that strengthens the bonds between us all.",
    "American Heart Nation is more than a charitable organization – it's a movement. Through each act of kindness, they build a brighter future, reminding us that generosity is the foundation of a better world."
];

const testimonyAuthors = [
    "Emily Carter",
    "Michael Rodriguez",
    "Sophia Allen",
    "Daniel Lewis",
    "Olivia Turner"
];


export {
    americanCounties,
    americanCounties2,
    americanNames,
    americanNames2,
    testimonyQuote,
    testimonyAuthors
}