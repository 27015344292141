import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import SignupFormExportTemplate from './component/auth/SignupForm';
import SigninFormExportTemplate from './component/auth/SigninForm';
import { DataProvider } from './component/dashboard/DataProvider';
import DashboardBaseTemplate from './component/dashboard/DashboardBaseTemplate';
import HomePage from './component/landing/HomePage';
import About from './component/landing/About';
import  ResetPasswordFormExportTemplate from './component/auth/ResetPasswordForm';
import NewsDetail from './component/landing/NewsDetail';

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/signup" element={<SignupFormExportTemplate />} />
				<Route path="/signin" element={<SigninFormExportTemplate />} />
				<Route path="/reset-password" element={<ResetPasswordFormExportTemplate />} />
				<Route path="/dashboard" element={
					<DataProvider>
						<DashboardBaseTemplate />
					</DataProvider>
				}/>
				<Route path="/home" element={<HomePage/>} />
				<Route path="/about" element={<About/>} />
				<Route path="/request-help" element={
					<DataProvider>
						<DashboardBaseTemplate />
					</DataProvider>
				}/>
				<Route path="/donate-now" element={
					<DataProvider>
						<DashboardBaseTemplate />
					</DataProvider>
				}/>
				
				{/*path for specific news detail*/}
				<Route path="/news/:title" element={<NewsDetail />} />
			</Routes>
		</Router>
	);
}

export default App;
