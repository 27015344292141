import React, { useState } from 'react';


const ShowPassword = () => {
    return (
        <svg
            width="19px"
            height="13px"
            viewBox="0 0 19 13"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Show Password</title>
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    transform="translate(0.409091, 0.318182)"
                    fill="#6c757d"
                >
                    <path
                        d="M9.09090909,0 C12.4197716,0.116650088 15.5080499,1.68545514 17.4677442,4.25532282 C18.4198428,5.37941519 18.4198428,6.98520009 17.4677442,8.10929245 C15.507837,10.6787903 12.4195922,12.2472175 9.09090909,12.3636364 C5.76204658,12.2469863 2.67376826,10.6781812 0.714073979,8.10831354 C-0.23802466,6.98422118 -0.23802466,5.37843628 0.714073979,4.25434391 C2.67398113,1.68484604 5.76222601,0.116418871 9.09090909,0 Z M9.09090909,1.54569927 C6.25599556,1.66315137 3.63477553,3.01656357 1.98127037,5.21661268 C1.49867472,5.77735611 1.49867472,6.58530134 1.98127037,7.14604477 C3.63456142,9.34646412 6.25581523,10.7002547 9.09090909,10.8179371 C11.925595,10.6998145 14.5463461,9.34608822 16.1995217,7.14604477 C16.6821174,6.58530134 16.6821174,5.77735611 16.1995217,5.21661268 C14.546132,3.01693947 11.9254147,1.66359151 9.09090909,1.54569927 Z M9.08986763,3.63636385 C9.76514457,3.63608776 10.4128585,3.90414682 10.8904491,4.38154208 C11.3680397,4.85893734 11.6363636,5.50654153 11.6363636,6.18181851 C11.6363636,7.58749858 10.4969363,8.72708109 9.09125617,8.72727273 C7.68557609,8.72746437 6.54583814,7.58819259 6.54545464,6.18251257 C6.54507134,4.77683255 7.68418765,3.63693922 9.08986763,3.63636385 Z M9.08986763,4.91013237 L9.03570902,4.91013237 C9.15736318,5.25662716 9.06957612,5.64218497 8.8099051,5.90185599 C8.55023408,6.161527 8.16467625,6.24931407 7.81818189,6.12765991 L7.81818189,6.18181851 C7.81818189,6.63652017 8.06076232,7.05668182 8.45454551,7.28403265 C8.84832869,7.51138348 9.33348959,7.51138348 9.72727278,7.28403265 C10.121056,7.05668182 10.3636364,6.63652017 10.3636364,6.18181851 C10.3633603,5.84427023 10.2290049,5.52065675 9.99012687,5.28216939 C9.75124887,5.04368203 9.42741592,4.90985646 9.08986763,4.91013237 Z"
                    />
                </g>
            </g>
        </svg>
    );
};

const HidePassword = () => {
    return (
        <svg
            width="18px"
            height="13px"
            viewBox="0 0 18 13"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Hide Password</title>
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    transform="translate(0.000000, -1.000000)"
                    fill="#4B5156"
                >
                    <g transform="translate(0.000000, 1.249700)">
                        <path
                            d="M17.024,8.284 C17.952,7.136 17.952,5.495 17.024,4.347 C15.114,1.722 12.104,0.119 8.86,-7.10542736e-15 C5.616,0.119 2.606,1.722 0.696,4.346 C-0.232,5.494 -0.232,7.135 0.696,8.283 C2.606,10.908 5.616,12.512 8.86,12.631 C12.104,12.512 15.114,10.909 17.024,8.284 M8.86,11.052 C11.623,10.93 14.177,9.548 15.788,7.301 C16.258,6.727 16.258,5.902 15.788,5.329 C14.176,3.082 11.623,1.7 8.86,1.579 C6.097,1.699 3.543,3.082 1.931,5.329 C1.461,5.902 1.461,6.727 1.931,7.301 C3.542,9.548 6.097,10.931 8.86,11.052"
                            fill="#6c757d"
                        />
                        <line
                            x1="2.1348"
                            y1="11.4999"
                            x2="16.1348"
                            y2="0.4999"
                            stroke="#6c757d"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const PasswordInput = ({
    name,
    id,
    label,
    inputError,
    handleChange,
    isDisabled
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="input-wrapper">
            <label htmlFor={id}>{label}</label>
            <div className="password-input-wrapper">
                <input
                    name={name}
                    id={id}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="***********"
                    onChange={handleChange}
                    disabled={isDisabled}
                />
                {/*password toggle*/}
                <span className="toggle-password-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                        <ShowPassword />
                    ) : (
                        <HidePassword />
                    )}
                </span>
            </div>
            {
                inputError && (
                    <div className="input-error">{inputError}</div>
                )
            }
        </div>
    );
};

const TextInput = ({
    name,
    id,
    label,
    type,
    value,
    placeholder,
    inputError,
    handleChange,
    isDisabled
}) => {
    return(
        <div className="input-wrapper">
            <label htmlFor={id}>{label}</label>
            <input 
                type={type}
                id={id} 
                name={name} 
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                disabled={isDisabled}
            />
            {
                inputError && (
                    <div className='input-error'>{inputError}</div>
                )
            }
        </div>
    )
}

const CustomTextArea = ({
    name,
    id,
    label,
    value,
    maxLength,
    placeholder,
    inputError,
    handleChange,
    isDisabled
}) => {
    return(
        <div className="input-wrapper">
            <label htmlFor={id}>{label}</label>
            <textarea 
                id={id} 
                name={name} 
                placeholder={placeholder}
                onChange={handleChange}
                disabled={isDisabled}
                style={{ height: 'auto' }}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                maxLength={maxLength}
                value={value}
            >
            </textarea>
            {
                inputError && (
                    <div className='input-error'>{inputError}</div>
                )
            }
        </div>
    )
}

const LoadingIndicator = () => {
    return(
        <svg
            width="120"
            height="30"
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
        >
            <circle cx="5" cy="10" r="5">
                <animate
                    attributeName="cy"
                    begin="0s"
                    dur="0.6s"
                    values="15;5;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
            <circle cx="20" cy="10" r="5">
                <animate
                    attributeName="cy"
                    begin="0.2s"
                    dur="0.6s"
                    values="15;5;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
            <circle cx="35" cy="10" r="5">
                <animate
                    attributeName="cy"
                    begin="0.4s"
                    dur="0.6s"
                    values="15;5;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    )
}

const constraints = {
    validCharsRegex : /^[a-zA-Z'-]+$/,
    validCharWithSpaceRegex: /^[a-zA-Z\s'-]+$/,
    validTextWithSpaceRegex: /^[0-9a-zA-Z\s'-]+$/,
    fullnameRegex: /^[a-zA-Z\s'-]+$/,
    emailRegex : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    
    specialChar: /[$&+,:;=?@#|'<>.^*()%!-]/,
    length: /^.{8,30}$/,
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    number: /\d/
};

const allPropertiesEqual = (obj,val) => {
    const values = Object.values(obj);
    return values.every(value => value === val);
};



export {
    // constants
    constraints,

    // components
    TextInput,
    PasswordInput,
    LoadingIndicator,
    allPropertiesEqual,
    CustomTextArea,
}
