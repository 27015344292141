const randomGenerator = (max,min) => {
    return Math.floor(Math.random()*(max-min)+min)
}

const blogTimeFormat = (dateString) => {
    const entryDate = new Date(dateString); // Convert the date string to a Date object
    const currentDate = new Date(); // Get the current date and time

    const timeDifference = currentDate - entryDate; // Time difference in milliseconds
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

    if (hoursDifference < 24) {
        return `${hoursDifference} hours ago`;
    } else {
        return entryDate.toLocaleString('en-US'); // Return the local time string in 'en-US' format
    }
}

export {
    randomGenerator,
    blogTimeFormat,
}