import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';

import { 
    TextInput,
    PasswordInput,
    constraints,
    allPropertiesEqual,
    LoadingIndicator
} from './AuthFormComponents';
import AuthTemplate from './AuthTemplate';
import { 
    moveSlides,
    baseEndPoint
} from '../utils';
import VerifySignupCodeComponent from './VerifySignupCodeComponent';

const SignupForm = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [inputErrorMsg, setInputErrorMsg] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: ''
    })

    const [loading, setLoading] = useState(false)
    const [slideIndex, setSlideIndex] = useState(0)
    const [formError, setFormError] = useState('')
    const [verificationNote, setVerificationNote] = useState('')

    const handleChange = (e) => {

        const value = e.target.value

        setFormData({
            ...formData,
            [e.target.name]: value
        });
    }

    const validateInput = () => {
        // set loading true
        setLoading(true)

        let aggregateCheck = true;
        let tempErrors = {};  // Temp object to store errors
    
        const { firstname, lastname, email, password, confirmPassword } = formData;
        
        // firstname
        if (firstname.trim().length > 2 && constraints.validCharsRegex.test(firstname.trim())) {
            tempErrors.firstname = '';  // Clear error
        } else {
            aggregateCheck = false;
            tempErrors.firstname = 'Enter a valid first name.';  // Set error message
        }
    
        // lastname
        if (lastname.trim().length > 2 && constraints.validCharsRegex.test(lastname.trim())) {
            tempErrors.lastname = '';  // Clear error
        } else {
            aggregateCheck = false;
            tempErrors.lastname = 'Enter a valid lastname.';  // Set error message
        }
    
        // email
        if (constraints.emailRegex.test(email.trim())) {
            tempErrors.email = '';  // Clear error
        } else {  
            aggregateCheck = false;
            tempErrors.email = 'Please enter a valid email address.';  // Set error message
        }
    
        // password
        const validationResults = {
            uppercase: constraints.uppercase.test(password.trim()),
            lowercase: constraints.lowercase.test(password.trim()),
            number: constraints.number.test(password.trim())
        };
    
        const allTrue = allPropertiesEqual(validationResults,true)  // Validate password criteria
        if (!allTrue) {
            aggregateCheck = false;
            tempErrors.password = 'Passwords must be a mix of lowercase, uppercase letters, and digits.';
        } else if (password.trim().length < 8) {
            aggregateCheck = false;
            tempErrors.password = 'Password too short.';
        } else if (password.trim().length > 30) {
            aggregateCheck = false;
            tempErrors.password = 'Password too long.';
        } else {
            tempErrors.password = '';  // Clear error
        }
    
        // confirm password
        if (password.trim() === confirmPassword.trim()) {
            tempErrors.confirmPassword = '';  // Clear error
        } else {
            aggregateCheck = false;
            tempErrors.confirmPassword = "Passwords don't match.";
        }
    
        // Update the form error state with all accumulated error messages
        setInputErrorMsg({
            ...inputErrorMsg,
            ...tempErrors
        });
        
        // set loading to false
        setLoading(false)

        return aggregateCheck;
    };
    
    const checkUserExistence = async () => {
        // set loading true
        setLoading(true)

        let funcResponse = true

        try {
            const response = await baseEndPoint.post('/api/registration-detail-existence/', {
                email: formData.email
            });

            if(response.data.email === "Non-available"){
                setInputErrorMsg({
                    ...inputErrorMsg,
                    'email': "An account with this email address exists!"
                });

                funcResponse = false
            }else if(response.data.email === "Available"){
                funcResponse = true
            }

        } catch (err) {
            // display the submission error if the slideIndex is 0
            setFormError('An error occurred. Please try again.');

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    }

    const sendSignupVerificationCode = async () => {
        // set loading true
        setLoading(true)

        let funcResponse = true

        try {
            const response = await baseEndPoint.post('/api/send-email-verification-code/', {
                email: formData.email,
                first_name: formData.firstname,
                last_name: formData.lastname,
            });

            setVerificationNote(response.data.message)
        } catch (err) {
            // display the submission error if the slideIndex is 0
            setFormError('An error occurred. Please try again.');

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    }

    const handleSubmit = async () => {
        // clear the form error if any
        setFormError('')

        if(!loading){
            //set loading to true
            setLoading(true)
            
            // call the function to validate inputs
            const inputValid = validateInput()  
            if(inputValid){
                // check if the user exists
                const userExistsTest = await checkUserExistence()

                if(userExistsTest){
                    // send a verification code
                    const codeSent = await sendSignupVerificationCode()

                    if(codeSent){
                        // move to the code verification slide
                        moveSlides(
                            slideIndex,
                            setSlideIndex,
                            ".registration-auth-form>div",
                            "next"
                        )
                    }
                }
            } 
        }
    }

    const handlePrevious = () => {
        moveSlides(
            slideIndex,
            setSlideIndex,
            ".registration-auth-form>div",
            "previous"
        )
    }

    useEffect(()=>{
        /*Change the content of the title bar*/
        document.title = 'Signup'

        // check if there's an access token
        const accessToken = localStorage.getItem("access_token")
        if(accessToken){
            window.history.replaceState(null, null, "/dashboard");
        }
    },[])

    return(
        <div className="registration-auth-form auth-form form">
            {/*signup form*/}
            <div>
                <h2>Account Details</h2>
                {/* fullname wrapper */}
                <div className="fullname-wrapper">
                    {/* first name */}
                    <TextInput
                        name="firstname"                            
                        id="firstname"
                        label="First name"
                        type='text'
                        placeholder='john'
                        inputError={inputErrorMsg.firstname}
                        handleChange={handleChange}
                        isDisabled={loading}
                    />
                    {/* last name */}
                    <TextInput
                        name="lastname"                            
                        id="lastname"
                        label="Last name"
                        type='text'
                        placeholder='doe'
                        inputError={inputErrorMsg.lastname}
                        handleChange={handleChange}
                        isDisabled={loading}
                    />
                </div>
                {/* email wrapper */}
                <TextInput
                    name="email"                            
                    id="email"
                    label="Email Address"
                    type='email'
                    placeholder='johndoe@gmail.com'
                    inputError={inputErrorMsg.email}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/* Password wrapper */}
                <div className="passwords-wrapper">
                    {/*password*/}
                    <PasswordInput
                        name="password"
                        id="password"
                        label="Password"
                        inputError={inputErrorMsg.password}
                        handleChange={handleChange}
                        isDisabled={loading}
                    />
                    {/*confirm password*/}
                    <PasswordInput
                        name="confirmPassword"
                        id="confirmPassword"
                        label="Confirm Password"
                        inputError={inputErrorMsg.confirmPassword}
                        handleChange={handleChange}
                        isDisabled={loading}
                    />
                </div>

                {/*form error*/}
                {
                    formError && (<div className="input-error form-error">
                        {formError}
                    </div>)
                }
                {/* submit button */}
                <button 
                    className={`green-button ${loading && 'green-button-disabled'}`}
                    onClick={handleSubmit}
                >
                    {loading && (<LoadingIndicator/>)}
                    Continue
                </button>
            </div>

            {/*verification code form*/}
            <VerifySignupCodeComponent 
                handlePrevious={handlePrevious}
                verificationNote={verificationNote}
                formData={formData}
            />
        </div>
    )
}

const AltOptionActionComponent = () => <span><Link to="/signin">Signin</Link></span>;

const SignupFormExportTemplate = () => (
        <AuthTemplate
            subText="You are welcome"
            headText="Create an account"
            FormComponent={SignupForm}
            altOptionNote="Already have an account?"
            AltOptionActionComponent={AltOptionActionComponent}
        />
)


export default SignupFormExportTemplate