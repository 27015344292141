
import '../../component_styles/landing/about.css';

// component import
import BottomBar from "./homepage/BottomBar";
import TopBar from "./TopBar";


const About = () => (
    <div>
        {/* top bar*/}
        <TopBar />

        {/*show wrapper*/}
        <div className="show-wrapper">
            <div className="show">
                <div className="show1">
                    <img
                        src="https://images.gofundme.com/ZGccSKsA0orEyMoO4f99xcIcTBQ=/720x405/https://d2g8igdw686xgo.cloudfront.net/77253619_1703217785156895_r.png"
                        alt="Couples looking at each other"
                    />
                </div>
                <div className="show2">
                    <img
                        src="https://images.gofundme.com/FgQglXfbLJcVbWh7uK9BV_z1MZE=/720x405/https://d2g8igdw686xgo.cloudfront.net/77149539_1702705031786516_r.jpeg"
                        alt="Lady in blue enjoying the cool"
                    />
                </div>
                <div className="show3">
                    <img
                        src="https://images.gofundme.com/QWqG43bCRq6hxOBDsrUjWh0jMZ0=/720x405/https://d2g8igdw686xgo.cloudfront.net/77202051_1702978169888851_r.jpeg"
                        alt="An adorable lad"
                    />
                </div>
                <div className="show4">
                    <img
                        src="https://images.gofundme.com/wH-7RyzC8-d13KczkvN1iWdC680=/720x405/https://d2g8igdw686xgo.cloudfront.net/77020483_1702113578835006_r.jpeg"
                        alt="Mom with kids smiling on grass"
                    />
                </div>
                <div className="show5">
                    <img
                        src="https://images.gofundme.com/hYVqUuE39w0MRDaBsvdD_qJIk3Q=/720x405/https://d2g8igdw686xgo.cloudfront.net/77210371_1703015826930569_r.jpeg"
                        alt="A couple posing for a photograph"
                    />
                </div>
            </div>
        </div>

        {/*description*/}
        <div className="about">
            <div className="about-heading"> About us</div>
            <p>
                American Native Heart Foundation is a unique organization that combines the power of charity work with political advocacy. 
                Our mission is to create positive change and address social issues by leveraging both charitable initiatives and political 
                influence. We believe that by working hand in hand, we can make a greater impact and bring about lasting change in our society.
            </p>
            <p>
                One of the key aspects of our foundation is charity work. We focus on providing support and assistance to different 
                communities, including differently abled individuals and elderly people in need of healthcare. Our efforts are aimed 
                at improving the lives of those who may face challenges in accessing essential services and resources. 
                Through various programs, we provide medical assistance, educational support, and basic necessities to those in need.
                But what sets American Heart Foundation apart is our affiliation with politics. 
            </p>
            <p>
                We understand that to truly address the root 
                causes of social issues, we need to be involved in the political landscape. We work tirelessly to advocate for policies 
                and legislation that promote equality, justice, and inclusivity. By engaging with lawmakers, participating in public 
                forums, and raising awareness about important issues, we strive to create systemic change that benefits the most 
                vulnerable members of our society.
                American Heart Foundation also actively encourages community involvement and civic engagement. We believe that everyone 
                has a voice and can contribute to shaping a better future. Through our political education programs, we empower 
                individuals to understand the political process, engage in meaningful discussions, and take action to influence change. 
                We organize workshops, seminars, and community events that promote active citizenship and encourage people to exercise their right to vote.
            </p>
            <p>
                What's truly remarkable about American Heart Foundation is our commitment to transparency and accountability. 
                We believe that trust is essential in both charity work and politics. We ensure that all donations and resources 
                are used effectively and efficiently, and we provide regular updates to our supporters about the impact of their 
                initiatives. By being transparent about our financials and operations, we build trust and maintain the integrity of 
                our organization.

                Another aspect that makes American Heart Foundation stand out is our emphasis on collaboration. We actively seek 
                partnerships with other organizations, both within the charity sector and the political sphere. 
            </p>
            <p>
                By joining forces, we can pool resources, share knowledge, and amplify our impact. This collaborative approach allows 
                us to tackle complex issues from multiple angles and find innovative solutions.
                Overall, American Heart Foundation is a dynamic and forward-thinking organization that is making a real difference 
                in our community.<br/>
                If you'd like more information about either foundation or have any specific questions, please let me know! I'm here to 
                provide further details and engage in a discussion about our work.”
            </p>
        </div>

        {/*bottom bar*/}
        <BottomBar />
    </div>
)

export default About