import React from 'react';
import { Link } from 'react-router-dom';

import '../../component_styles/landing/top_bar.css'

import { 
    TopBarLogo
} from './SVGDump';

const TopBar = () => {
    return(
        <div className="top-bar">
            <Link to="/">
                <div className="top-bar-title">
                    <span>
                        <TopBarLogo />
                    </span>
                    <span>
                        American<br/>
                        Native heart<br/>
                        Foundation
                    </span>
                </div>
            </Link>
            <div className="top-nav">
                <div className="top-bar-nav-2">
                    <Link to="/donate-now">
                        Donation
                    </Link>
                </div>
                <div className="top-bar-nav-3">
                    <Link to="/about">
                        About
                    </Link>
                </div>
                <div className="top-bar-nav-4">
                    <Link to="/request-help">
                        Request help
                    </Link>
                </div>
                <div className="top-bar-nav-5">
                    <Link to="/dashboard">
                        Join Us
                    </Link>
                </div>
            </div>
            <button className="top-bar-contact-butt butt">
                <a href="mailto:support@americannativeheartfoundation.com">
                    <div className="top-bar-contact-butt-text-wrapper inner-butt">
                        Contact us
                    </div>
                </a>
            </button>
        </div>
    )
}

export default TopBar