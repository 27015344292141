import { useEffect } from 'react'
import { Link } from "react-router-dom"
import { blogTimeFormat } from "../homepage/_util"
import { flexifyColumns } from '../../utils'

const NewsGrid = ({data}) => {
    const htmlToPlainText = (htmlString) => {
        // Create a temporary DOM element
        var tempElement = document.createElement('div');
        
        // Set the HTML content
        tempElement.innerHTML = htmlString;
        
        // Extract plain text using innerText or textContent
        return tempElement.innerText || tempElement.textContent;
    }

    useEffect(()=>{
        if(data && data.length>1){
            const lt1280 = matchMedia('(max-width: 1280px)').matches 
            const childMinWidth = lt1280 ? '250px': '350px'
            flexifyColumns({
                selector: '.blog-story-listing',
                gap: 50,
                childMinWidth: childMinWidth
            })
        }
    },[data])

    return(
        <div className="blog-story-listing">
            {/* story listing */}
            {
                data && (
                    data.map((story,index)=>(
                        <div key={index}>
                            <div className="blog-story-listing-entry">
                                {/* tag */}
                                <div className="blog-story-tag">
                                    <div>
                                        {story.tags.split(',')[0]}
                                    </div>
                                </div>

                                {/* image */}
                                <div className="blog-story-image-wrapper">
                                    <img
                                        src={story.cover_image_url}
                                        alt={
                                            story.cover_image_description? 
                                                story.cover_image_description:
                                                story.title
                                        }
                                    />
                                </div>

                                {/* published detail */}
                                <div className="published-detail">
                                    <span>Posted : </span>
                                    <span>{blogTimeFormat(story.date_published)}</span>
                                </div>
                                {/* brief */}
                                <p className="blog-story-short">
                                    {
                                        (()=>{
                                            const storyDetail = htmlToPlainText(story.detail)
                                            return (storyDetail.length<420) ? storyDetail :
                                                storyDetail.slice(0,420) + '...'
                                        })()
                                    }
                                </p>
                                {/* read more */}
                                <div className="read-more-action-wrapper">
                                    <Link to={`/news/${story.title}`}>
                                        <button>Read more</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        </div>
    )
}

export default NewsGrid