import React,{ useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

// style import
import '../../component_styles/landing/news_detail.css';

import { blogTimeFormat } from "./homepage/_util"
import { baseEndPoint, flexifyColumns } from '../utils';
import { LoadingPlaceholder } from '../add_ons/AddOns';
import TopBar from './TopBar';
import BottomBar from './homepage/BottomBar';
import NewsGrid from './news/NewsGrid';
import CommentForm from './news/CommentForm';

const NewsDetail = () => {
    const location = useLocation();

    const [newsDetails,setNewsDetails] = useState(null)
    const [latestNews, setLatestNews] = useState(null)
    const [comments, setComment] = useState(null)
    const [pageContentError, setPageContentError] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async () => {
            try{
                const response = await baseEndPoint.get(`/api${location.pathname}/`)

                setNewsDetails(response.data.news_details);
                setLatestNews(response.data.latest_news);
                setComment(response.data.comments)
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setPageContentError('The  news content was not found');
                } else {
                    setPageContentError('An error occurred while fetching the news content.');
                }

            } finally {
                setLoading(false);
            }
        }

        fetchData()

    },[location.pathname])

    useEffect(() => {
        if (comments && comments.length > 1){
            flexifyColumns({
                selector: '.blog-story-detail-comment-wrapper',
                gap: 30,
                childMinWidth: '250px'
            })
        }
    },[comments])

    return(
        <div>
            {/* top bar */}
            <TopBar/>


            <div className="blog-story-detail">

                {/*loading placeholder*/}
                {
                    loading && (
                        <LoadingPlaceholder/>
                    )
                }

                {/* content loading error */}
                {
                    pageContentError && (
                        <div 
                            className="error-note"
                            style={{marginTop: "20px"}}
                        >
                            {pageContentError}
                        </div>
                    )
                }

                <h1>{newsDetails && (newsDetails.title)}</h1>

                {/*doubled underline*/}
                {
                    newsDetails && (
                        <div className="doubled-underline">
                            <div></div>
                            <div></div>
                        </div>
                    )
                }

                {/*main story*/}
                {
                    newsDetails && (
                        <div className="blog-story-detail-main">
                            {/* detail image */}
                            <div className="blog-story-detail-image-wrapper">
                                <img
                                    src={newsDetails.cover_image_url}
                                    alt={
                                        newsDetails.cover_image_description? 
                                            newsDetails.cover_image_description:
                                            newsDetails.title
                                    }
                                />
                            </div>
                            {/* detail tag list */}
                            <div className="blog-story-detail-tag-list">
                                {
                                    newsDetails.tags.split(',').map((tag,index)=>(
                                        <div key={index}>{tag}</div>
                                    ))
                                }
                            </div>
                            {/*published detail*/}
                            <div className="published-detail time-posted">
                                <span>Posted : </span>
                                <span>{blogTimeFormat(newsDetails.date_published)}</span>
                            </div>

                            {/* description */}
                            <p className="blog-story-full-desc" dangerouslySetInnerHTML={{ __html: newsDetails.detail }}/>

                            {/* Author */}
                            <div className="published-detail published-author">
                                <span>Published by : </span>
                                <span>{newsDetails.author_name}</span>
                            </div>
                        </div>
                    )
                }

                {/* comments */}
                <div className="blog-story-detail-comment-section">
                    <h2>Comments</h2>

                    {/* comments */}
                    <div className="blog-story-detail-comment-wrapper">
                        {
                            comments && (
                                comments.length < 1 ? (
                                    <div className="no-record-found">
                                        No comments found!
                                    </div>
                                ):(
                                    comments.map((comment,index)=>(
                                        <div 
                                            key={index}
                                            className="blog-story-detail-comment"
                                        >
                                            <p>{comment.comment}</p>    
                                            <div className="comment-published-date">
                                                <span>Date Posted</span>
                                                <span>
                                                    {
                                                        new Date(comment.date_published).toLocaleString('en-US')
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                ) 
                            )
                        }
                    </div>

                    {/* add a comment */}
                    {
                        newsDetails && (
                            <CommentForm newsId={newsDetails.id}/>
                        )
                    }
                </div>

                {/* related content */}
                {
                    latestNews && (
                        <div className="related-content">
                            <h2>Related contents</h2>
                        
                            {/* listing */}
                            <NewsGrid data={latestNews}/>
                        </div>
                    )
                }
            </div>


            {/* bottom bar*/}
            <BottomBar/>
        </div>
    )
}

export default NewsDetail