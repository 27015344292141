import React,{ useState, useEffect, useContext } from 'react';

// context import
import {DataContext} from '../DataProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/campaign_pane.css'

// component import
import { 
    CampaignIcon,
    CheckMarkIcon,
    RegisteredCheckCircle,
} from '../SvgDump';
import DashboardPane from './DashboardPane';
import { 
    baseEndPoint, 
    hypenDateFormat, 
} from '../../utils';

const PaneContent = () => {
    const { data, setData } = useContext(DataContext);

    const [registrationStatus, setRegistrationStatus] = useState(null)

    const registerUser = async (id) => {
        // function response
        let funcResponse = false

        // access token
        const accessToken = localStorage.getItem("access_token")

        try {
            await baseEndPoint.post('/api/join-campaign/', 
                {   // payload
                    campaign_id: id
                },
                {   // headers
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Add the Authorization header
                    },
                }
            );
            
            funcResponse = true

        } catch (err) {
            if(err && err.response.status === 400){
                // log err.response.data.message
                console.log(err.response.data.message)
            }else{
                console.log('An error occured')
            }
        } finally {
            return funcResponse
        }
    }

    const handleRegistration = async (id) => {
        const registration_state = registrationStatus[id] === false
        
        if(registration_state){
            // make the registeration status null
            setRegistrationStatus((prev)=>({
                ...prev,
                [id]: null
            }))    

            // call the registerUser function
            const userRegistered = await registerUser(id)

            if (userRegistered){
                /** 
                 * modify the parent data which would 
                 * cause a chain reaction to this point
                */
                setData((prev) => ({
                    ...prev,
                    records: {
                        ...prev.records,
                        campaigns: prev.records.campaigns.map(campaign =>
                            campaign.id === id 
                                ? { ...campaign, registration_status: true } 
                                : campaign
                        )
                    }
                }));
            } else {
                /** 
                 * else modify only the registrationStatus state 
                 * back to its initial
                 * 
                */
                setRegistrationStatus((prev)=>({
                    ...prev,
                    [id]: false
                }))
            }
        }
    }

    useEffect(()=>{
        // create a proxy object
        const proxyObj = {}
        // fill the object with campaign ids as entry, and status as values
        data.records.campaigns.forEach((item)=>(
            proxyObj[item.id] = item.registration_status
        ))

        // spread the proxy object into the registrationStatus state
        setRegistrationStatus(proxyObj)
    },[data, setRegistrationStatus])

    return(
        <div>
            {data && (
                <div className="campaign-pane">

                    {/*campaign titlebar*/}
                    <div className="pane-titlebar">
                        <div>
                            <CampaignIcon fill="#92cd00ff"/>
                            <h4>Fundraising Campaigns</h4>
                        </div>
                    </div>

                    <div className="pane-content">
                        {
                            data.records.campaigns.map((campaign,index) => (
                                <div key={index} className="campaign-pane-entry">
                                    {/* campaign title*/}
                                    <div className="pane-header">
                                        <h2>{campaign.title}</h2>
                                    </div>
                                    {/*campaign image*/}
                                    <div className="campaign-pane-img-wrapper">
                                        <img src={campaign.image_url} alt="Campaign cover"/>
                                    </div>
                                    {/*campaign numbers*/}
                                    <div className="campaign-pane-numbers">
                                        <div className="campaign-pane-numbers-entry">
                                            <div>Goal</div>
                                            <div>{`$${parseFloat(campaign.target_amount).toLocaleString('en-US')}`}</div>
                                        </div>
                                        <div className="campaign-pane-numbers-entry">
                                            <div>Amount raised</div>
                                            <div>{`$${parseFloat(campaign.amount_raised).toLocaleString('en-US')}`}</div>
                                        </div>
                                        <div className="campaign-pane-numbers-entry">
                                            <div>No of contributors</div>
                                            <div>{campaign.no_of_contributors}</div>
                                        </div>
                                    </div>
                                    {/*campaign dates*/}
                                    <div className="campaign-pane-dates">
                                        <div className="campaign-pane-dates-entry">
                                            <div>Date started</div>
                                            <div>{hypenDateFormat(campaign.date_started)}</div>
                                        </div>
                                        <div className="campaign-pane-dates-entry">
                                            <div>Date ended</div>
                                            <div>{campaign.date_ended ? hypenDateFormat(campaign.date_ended):"na"}</div>
                                        </div>
                                    </div>

                                    {/*campaign description*/}
                                    <p>
                                        {campaign.description}
                                    </p>

                                    {/*campaign action register*/}
                                    <div className="campaign-pane-reg-action-btns">
                                        {/*toggle button*/}
                                        <div className="switch" id="switchButton">
                                            
                                            {/*switch underlay*/}
                                            <div className="switch-underlay">
                                                <div>
                                                    {/*check mark*/}
                                                    <CheckMarkIcon />
                                                </div>
                                                <div>
                                                    <RegisteredCheckCircle />
                                                </div>
                                            </div>

                                            {/*switch overlay*/}
                                            <div className="switch-overlay">
                                                <button 
                                                    className={`toggle ${campaign.registration_status  && 'active'} ${registrationStatus && registrationStatus[campaign.id] === null && 'disabled'}`}
                                                    onClick={()=>{handleRegistration(campaign.id)}}
                                                    style={  
                                                            campaign.registration_status?{transform:'translateX(100%)'}:{}
                                                    }
                                                >
                                                    {
                                                        (()=>{
                                                            const regStatus = registrationStatus && registrationStatus[campaign.id]
                                                            return regStatus === false ? 'Register' :
                                                                regStatus === null ? 'Loading...' :
                                                                    regStatus && 'Registered'
                                                        })()
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
    
                </div>
            )}  
        </div>
    )
}

const CampaignPane = () => (<DashboardPane PaneDataComponent={PaneContent}/>)


export default CampaignPane