import { useContext, useState } from "react"
import { useNavigate } from 'react-router-dom';

import { 
    allPropertiesEqual,
    constraints,
    LoadingIndicator, 
    PasswordInput, 
} from "../AuthFormComponents"
import { ResetPasswordContext } from "./ResetPasswordProvider"
import { baseEndPoint } from "../../utils";


const ResetPasswordThirdSlide = () => {
    const navigate = useNavigate();

    const { contextEmail, setContextEmail } = useContext(ResetPasswordContext)

    const [passwordData, setPasswordData] = useState({
        'password':'',
        'confirmPassword':''
    })
    const [passwordDataError, setPasswordDataError] = useState({
        'password':'',
        'confirmPassword':''
    })

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState('')
    const [formSuccess, setFormSuccess] = useState('')

    const handleChange = (e) => {
        const value = e.target.value

        setPasswordData((prev)=>({
            ...prev,
            [e.target.id]: value
        }))
    }

    const validateInput = () => {
        let aggregateCheck = true;
        let tempErrors = {};  // Temp object to store errors
    
        const { password, confirmPassword } = passwordData;
        
    
        // password
        const validationResults = {
            uppercase: constraints.uppercase.test(password.trim()),
            lowercase: constraints.lowercase.test(password.trim()),
            number: constraints.number.test(password.trim())
        };
    
        const allTrue = allPropertiesEqual(validationResults,true)  // Validate password criteria
        if (!allTrue) {
            aggregateCheck = false;
            tempErrors.password = 'Passwords must be a mix of lowercase, uppercase letters, and digits.';
        } else if (password.trim().length < 8) {
            aggregateCheck = false;
            tempErrors.password = 'Password too short.';
        } else if (password.trim().length > 30) {
            aggregateCheck = false;
            tempErrors.password = 'Password too long.';
        } else {
            tempErrors.password = '';  // Clear error
        }
    
        // confirm password
        if (password.trim() === confirmPassword.trim()) {
            tempErrors.confirmPassword = '';  // Clear error
        } else {
            aggregateCheck = false;
            tempErrors.confirmPassword = "Passwords don't match.";
        }
    
        // Update the form error state with all accumulated error messages
        setPasswordDataError({
            ...passwordDataError,
            ...tempErrors
        });
        
        // set loading to false
        setLoading(false)

        return aggregateCheck;
    };

    const changePassword = async () => {
        // show the loader
        setLoading(true)

        let funcResponse = true

        try {
            const response = await baseEndPoint.post('/api/change-password/', {
                email: contextEmail,
                password: passwordData.password
            });

            // set success message
            setFormSuccess(response.data.message)

            // empty the password data
            setPasswordData({
                password: "",
                confirmPassword:""
            })

            // empty the context email
            setContextEmail('')

        } catch (err) {
            const statusCode = err && err.response.status
            if (statusCode === 400 || statusCode === 404){
                setFormError(err.response.data.message)
            }else{
                // display the submission error if the slideIndex is 0
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false

            // hide the loader
            setLoading(false)
        } finally {
            // return the result of the probe user test
            return funcResponse
        }
    } 

    const handleSubmit = async () => {
        
        if(!loading){
            //set loading to true
            setLoading(true)

            // clear the form error if any
            setFormError('')
            
            // call the function to validate inputs
            const inputValid = validateInput()  
            if(inputValid){

                // change the password
                const passwordChanged = await changePassword()
                if(passwordChanged){
                    // navigate to signin after few seconds
                    setTimeout((()=>(
                        navigate('/signin')
                    )),2000)
                }
            } 
        }
    }

    return(
        <div className="reset-password-form reset-password-third-slide">
            <header>
                <h2>Change password</h2>	
            </header>
            {/* note */}
            <p className="verify-email-note">
                Set a new password
            </p>

            {/* Password wrapper */}
            <div className="passwords-wrapper">
                {/*password*/}
                <PasswordInput
                    name="password"
                    id="password"
                    label="Password"
                    inputError={passwordDataError.password}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/*confirm password*/}
                <PasswordInput
                    name="confirmPassword"
                    id="confirmPassword"
                    label="Confirm Password"
                    inputError={passwordDataError.confirm_password}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
            </div>

            {/*form error*/}
            {formError && (
                <div className="input-error form-error">{formError}</div>
            )}
            {/*form success*/}
            {formSuccess && (
                <div className="form-success">{formSuccess}</div>
            )}
            <button
                className={`green-button ${loading && 'green-button-disabled'}`}
                onClick={handleSubmit}
            >
                {loading && (<LoadingIndicator/>)}
                Sign up
            </button>
        </div>
    )
}

export default ResetPasswordThirdSlide;