import React from 'react';

// style import
import '../../component_styles/dashboard/loading_placeholder.css'

const LoadingPlaceholder = (height) => (
    <div 
        style={{height:`${(height||"300px")}`}}
        className="loading-placeholder-wrapper"
    >
        <div className="loading-placeholder"></div>
        <div className="loading-placeholder"></div>
        <div className="loading-placeholder"></div>
    </div>
)

export {
    LoadingPlaceholder,
}