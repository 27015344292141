import React,{useState, createContext} from 'react';

const MenuContext = createContext()

const MenuProvider = ({children}) => {

    const [menuItemState, setMenuItemState] = useState({
        'dashboard-preview-trigger': true,
        'donation-history-trigger': false,
        'account-info-trigger': false,
        'fundraising-campaign-trigger': false,
        'impact-updates-trigger': false,
        'event-registration-trigger': false,
        'request-help-trigger': false,
        'donate-now-trigger': false,
    })

    const [menuOpen, setMenuOpen] = useState(false)

    const switchMenuItemState = (itemId) => {
        const menuStateCopy = {}

        Object.keys(menuItemState).forEach((key)=>(
            key === itemId ? menuStateCopy[key] = true:menuStateCopy[key] = false
        ))
        setMenuItemState(menuStateCopy)

        // close the menu if open
        const lt912 = window.matchMedia("(max-width:912px)").matches; 
        lt912 && setMenuOpen(false)
    }

    return(
        <MenuContext.Provider value={{
            menuItemState, 
            setMenuItemState, 
            menuOpen, 
            setMenuOpen,
            switchMenuItemState,
         }}>
            {children}
        </MenuContext.Provider>
    )
}

export {
    MenuProvider,
    MenuContext,
}