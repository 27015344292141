import { createContext, useState } from "react"

const ResetPasswordContext = createContext()

const ResetPasswordProvider = ({children}) => {
    const [slideIndex, setSlideIndex] = useState(0)
    const [contextEmail, setContextEmail] = useState('')
    
    return(
        <ResetPasswordContext.Provider value={{ slideIndex, setSlideIndex, contextEmail, setContextEmail }}>
            {children}
        </ResetPasswordContext.Provider>
    )
}

export {
    ResetPasswordProvider,
    ResetPasswordContext
}