import { useEffect } from "react"


import '../../../component_styles/landing/homepage/testimonial_section.css';

// component import
import { DoubleQuotes } from "../SVGDump"
import { randomGenerator } from "./_util"
import { 
    testimonyAuthors, 
    testimonyQuote
} from "./add_ons"

const TestimonialSection = () => {

    useEffect(()=>{
        const testimonyNote = document.querySelector(".testimony-note")
        const testimonyAuthor = document.querySelector(".testimony-author")
        const ind=randomGenerator(5,0)
        testimonyNote.textContent=testimonyQuote[ind]
        testimonyAuthor.textContent=testimonyAuthors[ind]
    },[])

    return(
        <section className="testimonial-section">
            <div className="smaller-note-wrapper">
                <div className="smaller-note">TESTIMONIALS</div>
            </div>
            <div className="testimonial-heading heading-text">
                What people say about us
            </div>
            {/* testimonial wrapper */}
            <div className="testimonial-wrapper">
                <div className="quote-symbol">
                    <DoubleQuotes />
                </div>
                <div className="testimony-slide">
                    <div className="testimony">
                        <div className="testimony-note">
                        </div>
                        <div className="testimony-author">
                        </div>
                    </div>
                </div>
                <div className="index-indicator"><div></div></div>
            </div>
            {/* stats */}
            <div className="stats">
                <div>
                    <div className="numbers">0%</div>
                    <span>Platform charge</span>
                    <p>
                        We want you to know that you are charged 0% on all donations. 
                        Your entire contribution goes directly towards making a difference in the lives of those in need. 
                        Thank you for being a vital part of our mission.
                    </p>
                </div>
                <div>
                    <div className="numbers">$12M+</div>
                    <span>Donations given</span>
                    <p>
                        We've been able to give back over $12,000,000 in donations! 
                        Your generosity is creating a positive impact, touching lives, and making a real difference. 
                        Together, we're building a stronger, more compassionate community. 
                        Thank you for being a driving force behind our shared mission.
                    </p>
                </div>
                <div>
                    <div className="numbers">10k+</div>
                    <span>Active Donors</span>
                    <p>
                        We're thrilled to share the news that our community has grown to include over 10,000 donors! 
                        Your commitment to making a difference is truly inspiring. 
                        Together, we're building a network of compassion and generosity that has a lasting impact.
                    </p>
                </div>
                <div>
                    <div className="numbers">2000+</div>
                    <span>Success stories</span>
                    <p>
                        Over 2,000 success stories and counting! 
                        Your unwavering support has fueled these triumphs, creating a ripple effect of positivity. 
                        Thank you for being the driving force behind each success.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default TestimonialSection;