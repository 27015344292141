import { Link } from "react-router-dom";

import '../../../component_styles/landing/homepage/bottom_bar.css'

import { 
    FacebookIcon, 
    InstagramIcon, 
    TopBarLogo, 
    TwitterIcon, 
    YoutubeIcon, 
} from "../SVGDump";


const BottomBar = () => (
    <footer>
        <div className="top-bar-title">
            <span>
                <Link to="/">
                    <TopBarLogo />
                </Link> 
            </span>
            <span>
                <Link to="/">
                    American Native Heart
                </Link>
            </span>
        </div>
        <div className="top-nav">
            <div className="top-bar-nav-2">
                <Link to="/donate-now">
                    Donation
                </Link>
            </div>
            <div className="top-bar-nav-3">
                <Link to="/about">
                    About
                </Link>
            </div>
            <div className="top-bar-nav-4">
                <Link to="/request-help">
                    Request help
                </Link>
            </div>
            <div className="top-bar-nav-5">
                <Link to="/dashboard">
                    Join us
                </Link>
            </div>
            <div className="top-bar-nav-6">
                <a href="mailto:support@americannativeheartfoundation.com">
                    Contact us
                </a>
            </div>
        </div>
        {/*address and mobile no.*/}
        <div className="address-mobile">
            <div className="mobile">
                +1 747-231-0036
            </div>
            <div className="address">
                W Hillwood Path, Pine Ridge,<br/>
                FL 34465, USA
            </div>         
        </div>
        {/*social and copyright*/}
        <div className="bottom">            
            <div className="socials">   
                <span className="twitter">
                    <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                        <TwitterIcon />
                    </a>
                </span>
                <span className="facebook">
                    <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                        <FacebookIcon />
                    </a>
                </span>
                <span className="youtube">
                    <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                        <YoutubeIcon />
                    </a>
                </span>
                <span className="instagram">
                    <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                        <InstagramIcon />
                    </a>
                </span>
            </div>
            <div>
                © 2023 Shadient.co. All rights reserved.
            </div>
        </div>
    </footer>
)

export default BottomBar