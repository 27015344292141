import React,{useContext} from 'react';

// context import
import {DataContext} from '../DataProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/impact_updates_pane.css'

// component import
import { 
    ImpactIcon,
} from '../SvgDump';
import DashboardPane from './DashboardPane';
import PreviewImpactCards from './sub/PreviewImpactCards';

const ImpactContent = () => {
    const { data } = useContext(DataContext);

    return(
        <div>
            {data && (
                <div className="impact-update-pane">

                    {/*impact titlebar*/}
                    <div className="pane-titlebar">
                        <div>
                            <ImpactIcon fill="#92cd00ff"/>
                            <h4>Impact Updates</h4>
                        </div>
                    </div>

                    {/*impact content*/}
                    <div className="pane-content">
                        {/*impact header*/}
                        <div className="pane-header">
                            <h2>Touching testimonials</h2>
                        </div>

                        {/*preview impact cards*/}
                        <PreviewImpactCards data={data.records.impact_updates}/>
                        
                    </div>
    
                </div>
            )}  
        </div>
    )
}

const ImpactPane = () => (<DashboardPane PaneDataComponent={ImpactContent}/>)


export default ImpactPane