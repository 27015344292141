import { useState } from "react";
import { constraints, CustomTextArea, LoadingIndicator, TextInput } from "../../auth/AuthFormComponents";
import { baseEndPoint } from "../../utils";


const CommentForm = ({newsId}) => {
    const [formData, setFormData] = useState({
        name: '',
        comment: '',
    });

    const [inputErrorMsg, setInputErrorMsg] = useState({
        name: '',
        comment: '',
    })
    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState('')
    const [formSuccess, setFormSuccess] = useState('')

    const handleChange = (e) => {
        const value = e.target.value

        setFormData((prev)=>({
            ...prev,
            [e.target.id]: value
        }))
    }

    const validateInput = () => {
        // setLoading to true
        setLoading(true)

        // pick up validation parameters
        const {name, comment} = formData
        const trimmedName = name.trim()
        const trimmedComment = comment.trim()
        let aggregateCheck = true
        const proxyErrObj = {
            name: '',
            comment: ''
        }

        // name validation
        if (constraints.validCharsRegex.test(trimmedName) && trimmedName.length > 2) {
            proxyErrObj.name = '' 
        } else {
            proxyErrObj.name='Please enter a valid name.'
            aggregateCheck = false
        }

        // comment validation
        if ( trimmedComment.length > 20) {
            proxyErrObj.comment = ''    
        } else {
            proxyErrObj.comment = 'Please enter a valid message description. Min. length : 20 characters.'
            aggregateCheck = false
        }

        // unpack error messages if any
        setInputErrorMsg((prev)=>({
            ...prev,
            ...proxyErrObj
        }))

        // set loading to false
        setLoading(false)

        return aggregateCheck
    }

    const submitComment = async () => {
        // set loading to true
        setLoading(true)
        let funcResponse = true

        try {
            const response = await baseEndPoint.post(`api/news/${newsId}/comment/`, {
                comentator_name: formData.name,
                comment: formData.comment,
            });

            setFormSuccess(response.data.message)


            // empty the formdata
            setFormData({
                name: "",
                comment: ""
            })
        } catch (err) {
            if (err && err.response.status === 400){
                setFormError(err.response.data.message)
            }else{
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    }

    const handleSubmit = async () => {
        // clear the form error if any
        setFormError('')

        if(!loading){
            
            // call the function to validate inputs
            const inputValid = validateInput() 

            if(inputValid){
                // send data to the backend
                await submitComment()
            } 
        }
    }

    return(
        <div className="comment-form-wrapper">
            <div className="comment-form">
                <h3>Add a comment</h3>
                {/* name input */}
                <TextInput
                    name="name"
                    id="name"
                    type="text"
                    placeholder="name"
                    value={formData.name}
                    inputError={inputErrorMsg.name}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/*comment input*/}
                <CustomTextArea
                    name="comment"
                    id="comment"
                    value={formData.comment}
                    placeholder="comment detail"
                    inputError={inputErrorMsg.comment}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/* form error */}
                {formError && (<div className="input-error form-error">
                        {formError}
                    </div>
                )}
                {/* form success */}
                {
                    formSuccess && (
                            <div className="form-success">
                                {formSuccess}
                            </div>
                        )
                }
                <button
                    className={`green-button ${loading && 'green-button-disabled'}`}
                    onClick={handleSubmit}
                >
                    {
                        loading ? (
                            <LoadingIndicator/>
                        ):(
                            <span>submit</span>
                        )
                    }
                </button>
            </div>
        </div>
    )
}

export default CommentForm