import React,{ useState, useContext, useEffect } from 'react';

// context import
import {DataContext} from '../DataProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/user_info_pane.css'

// component import
import { 
    AccountInfoIcon,
    CheckMarkIcon,
    GoalLeavesIcon,
    GoalSlurIcon,
} from '../SvgDump';
import DashboardPane from './DashboardPane';

const UserInfoContent = () => {

    const { data } = useContext(DataContext);

    const [amountRemaining, setAmountRemaining] = useState(0)
    const [percentageDonated, setPercentageDonated] = useState(0)

    // Helper function to format the date
    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        const formattedTime = dateObject.toTimeString().split(' ')[0];
        
        return {
            date:`${formattedDate}`,
            time:`${formattedTime}`
        };
    };

    const extractTZInfo = (dateString) => {
        const date = new Date(dateString);

        // Get timezone offset in minutes (difference from UTC)
        const timezoneOffset = date.getTimezoneOffset();  

        // Convert the offset from minutes to hours
        const timezoneOffsetHours = timezoneOffset / 60;

        return (`Timezone offset: UTC${timezoneOffsetHours > 0 ? '-' : '+'}${Math.abs(timezoneOffsetHours)}`);

    }

    useEffect(()=>{
        const amountDonated = data.records.donation_history.reduce(
            (entry, curr) => entry + parseFloat(curr.amount_donated),0
        )
        const clientGoal = data.client_goal
        const amountRemaining = clientGoal-amountDonated > 0 ? clientGoal-amountDonated : 0
        
        // setting the amount remaining
        setAmountRemaining(amountRemaining)

        // calculating and setting the percentage donated
        const percentageDonated = (amountDonated/clientGoal) * 100
        setPercentageDonated(percentageDonated)

        // constructing and setting the gradient
        let gradientString;
        if ( percentageDonated <= 0 ){
            gradientString = "var(--goal-gray)"
        }else if( percentageDonated>0 && percentageDonated<50 ){
            gradientString = `linear-gradient(90deg,
                var(--goal-gray) 0%, var(--goal-yellow) ${percentageDonated-10}%,
                var(--goal-gray) ${percentageDonated+5}%
            )`
        }else if(percentageDonated>50 && percentageDonated<100){
            gradientString = `linear-gradient(90deg,
                var(--goal-gray) 5%, 
                var(--goal-yellow) 20%, var(--goal-yellow) 45%,
                var(--goal-green) 50%, var(--goal-green) ${percentageDonated}%,
                var(--goal-gray) ${percentageDonated+10}%
            )`
        }else if(percentageDonated >= 100){
            gradientString = `linear-gradient(90deg,
                var(--goal-gray) 5%, 
                var(--goal-yellow) 20%, var(--goal-yellow) 60%,
                var(--goal-green) 70%
            )`
        }
        document.querySelector(".campaign-goal-status-bar").style.background = gradientString
    },[data])

    return(
        <div>
            {data && (
                <div className="impact-update-pane">
                    <div className="user-info-pane">
                        {/*userinfo titlebar*/}
                        <div className="pane-titlebar">
                            <div>
                                <AccountInfoIcon fill="#92cd00ff"/>
                                <h4>User Information</h4>
                            </div>
                        </div>

                        
                        {/*userinfo content*/}
                        <div className="pane-content">
                            <div className="info-details">
                                {/*campaign goals*/}
                                <div className="campaign-goal-wrapper">
                                    <div className="campaign-goal">
                                        <div className="campaign-goal-details">
                                            <h2>
                                                Goal/Target
                                            </h2>
                                            {/*goal status*/}
                                            <div className="campaign-goal-status-bar-wrapper">
                                                <div className="campaign-goal-status-bar"></div>
                                                <div className="campaign-goal-status-percentage">{`${parseInt(percentageDonated)}%`}</div>
                                            </div>
                                            {/*amount remaining*/}
                                            <div className="amount-remaining">
                                                <div>
                                                    <h5>Amount remaining</h5>
                                                    <span>{`$${parseFloat(amountRemaining).toLocaleString('en-US')}`}</span>
                                                </div>
                                                <div className="goal-slur-image-wrapper">
                                                    <GoalSlurIcon/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-goal-leaves">
                                            <GoalLeavesIcon />
                                        </div>
                                    </div>
                                </div>
                                {/*basic info*/}
                                <div className="basic-info">
                                    <div className="user-credentials">
                                        {/*first name*/}
                                        <div>
                                            <div className="tag">Firstname</div>
                                            <div className="value">{data.first_name}</div>
                                        </div>
                                        {/*last name*/}
                                        <div>
                                            <div className="tag">Lastname</div>
                                            <div className="value">{data.last_name}</div>
                                        </div>
                                        {/*email*/}
                                        <div>
                                            <div className="tag">Email</div>
                                            <div className="value">{data.email}</div>
                                        </div>
                                    </div>
                                    <div className="access-dates-et-user-agent">
                                        <div>
                                            <div className="tag">Created at</div>
                                            <div className="value">
                                                {formatDateTime(data.created_at).date}
                                                <span className="access-date-time">{formatDateTime(data.created_at).time}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tag">Last Login</div>
                                            <div className="value">
                                                {formatDateTime(data.last_login).date}
                                                <span className="access-date-time">
                                                    {formatDateTime(data.last_login).time}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tag user-agent">
                                            {
                                                data.user_agent.length>120 ?
                                                (data.user_agent.slice(0,120) + '...') : data.user_agent
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/*User preferences*/}
                                <div className="user-preferences">
                                    <h3>User Preferences</h3>
                                    <div className="preferences-wrapper">
                                        {/*email notification*/}
                                        <div>
                                            <div className="tag">
                                                Email Notifications
                                            </div>
                                            <div className="value">
                                                True
                                                <span className="true-check">
                                                    <CheckMarkIcon />
                                                </span>
                                            </div>
                                        </div>
                                        {/*email notification*/}
                                        <div>
                                            <div className="tag">
                                                Email Notifications
                                            </div>
                                            <div className="value">
                                                True 
                                                <span className="true-check">
                                                    <CheckMarkIcon />
                                                </span>
                                            </div>
                                        </div>
                                        {/*theme*/}
                                        <div>
                                            <div className="tag">
                                                Theme
                                            </div>
                                            <div className="value">
                                                Light
                                            </div>
                                        </div>
                                        {/*profile visibility*/}
                                        <div>
                                            <div className="tag">
                                                Profile Visibility
                                            </div>
                                            <div className="value">
                                                Private
                                            </div>
                                        </div>
                                        {/*preferred language*/}
                                        <div>
                                            <div className="tag">
                                                Preferred language
                                            </div>
                                            <div className="value">
                                                English
                                            </div>
                                        </div>
                                        {/*preferred language*/}
                                        <div>
                                            <div className="tag">
                                                Timezone
                                            </div>
                                            <div className="value">
                                                {extractTZInfo(data.last_login)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}  
        </div>
    )
}

const UserInfoPane = () => (<DashboardPane PaneDataComponent={UserInfoContent}/>)


export default UserInfoPane