import { Link } from "react-router-dom";

import '../../component_styles/auth/reset_password.css'

import AuthTemplate from "./AuthTemplate";
import ResetPasswordSlides from "./reset_password_slides/ResetPasswordSlides";

const AltOptionActionComponent = () => <span><Link to="/signup">Signup</Link></span>;

const ResetPasswordFormExportTemplate = () => (
    <AuthTemplate
        subText=""
        headText="Recover your account"
        FormComponent={ResetPasswordSlides}
        altOptionNote="Create an account"
        AltOptionActionComponent={AltOptionActionComponent}
    />
)

export default ResetPasswordFormExportTemplate