import React,{useContext} from 'react';

// context import
import {DataContext} from '../DataProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/db_pane.css'

// component import
import { 
    LoadingPlaceholder
} from '../../add_ons/AddOns';


const DashboardPane = ({PaneDataComponent}) => {
    const { loading, data } = useContext(DataContext);

    return(
        <div className="db-pane">
            {/*show the placeholder if the loading is true and data is null*/}
            {
                loading && data === null && (<LoadingPlaceholder/>)
            }
            {/**
             * Displays an error message if loading is false and data is still null
             * else displays the data
            */}
            {
                (loading === false) && (data === null) && (<div className="error-note">Content failed to load</div>)
            }
            {/* displays the data*/}
            <PaneDataComponent/>        
        </div>
    )
}

export default DashboardPane