import React,{ useEffect } from 'react';

import '../../component_styles/auth/signup.css';

const tdLogo = "https://res.cloudinary.com/derzcse6f/image/upload/v1726231195/logo_yz9lgf.svg"

const AuthTemplate = ({
    subText,
    headText,
    FormComponent,
    altOptionNote,
    AltOptionActionComponent,
}) => {

    useEffect(()=>{
        document.documentElement.style.backgroundColor = "#f5f7f9ff"
    },[])

    return(
        <div className="auth-container">
            {/*introductory*/}
            <div className="introductory">
                {/* td: trademark */}
                <div className="td">
                    <div className="td-image">
                        <img src={tdLogo} alt="Logo" />
                    </div>
                    <div className="td-name">
                        American
                        <br />
                        Native Heart
                        <br />
                        Foundation
                    </div>
                </div>
                <div className="introductory-message">
                    <span>{subText}</span>
                    <h1>{headText}</h1>
                </div>
            </div>
            {/*form container*/}
            <div className="form-container">
                <div className="form-doodle-container">
                    {/* form component */}
                    <FormComponent />

                    {/* doodle */}
                    <div className="doodle">
                        <img
                            src="https://res.cloudinary.com/derzcse6f/image/upload/v1726233890/gradient-world-humanitarian-day-illustration_23-2149004743_pwl7bb.jpg"
                            alt="Doodle depicting charity"
                        />
                    </div>
                </div>
                {/*Auth alternate option*/}
                <div className="auth-alt-option">
                    <span>{altOptionNote}</span>
                    <AltOptionActionComponent/>
                </div>
            </div>
        </div>
    )
}

export default AuthTemplate