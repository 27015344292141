import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import { 
    TextInput,
    PasswordInput,
    constraints,
    LoadingIndicator
} from './AuthFormComponents';
import AuthTemplate from './AuthTemplate';
import { 
    baseEndPoint
} from '../utils';

import '../../component_styles/auth/signin.css'

const SigninForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [inputErrorMsg, setInputErrorMsg] = useState({
        email: '',
        password: '',
    })

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState('')

    const handleChange = (e) => {

        const value = e.target.value.trim()

        setFormData({
            ...formData,
            [e.target.name]: value
        });
    }

    const handleCheckBoxChange = (e) => {
		if (e.currentTarget.checked) {
			// save the email address to the localStorage if it exists
			localStorage.setItem('email_address',formData.email)
			//console.log("Checkbox is checked");
		} else {
			// remove the email address from the localStorage if it exists
			localStorage.removeItem('email_address')
			//console.log("Checkbox is unchecked");
		}
	}

    const validateInput = () => {
        // set loading to true
        setLoading(true)

        let aggregateCheck = true;
        let tempErrors = {};  // Temp object to store errors
    
        const { email, password } = formData;
        
    
        // email
        if (constraints.emailRegex.test(email)) {
            tempErrors.email = '';  // Clear error
        } else {  
            aggregateCheck = false;
            tempErrors.email = 'Please enter a valid email address.';  // Set error message
        }

        if (password.length < 8) {
            aggregateCheck = false;
            tempErrors.password = 'Enter a valid password.';
        } else {
            tempErrors.password = '';  // Clear error
        }
    
    
        // Update the form error state with all accumulated error messages
        setInputErrorMsg({
            ...inputErrorMsg,
            ...tempErrors
        });
        
        // set loading to false
        setLoading(false)

        return aggregateCheck;
    };
    
    const signUserIn = async () => {
        // set loading to true
        setLoading(true)
        
        let funcResponse = true

        try {
            const response = await baseEndPoint.post('/api/signin/', {
                email: formData.email,
                password: formData.password,
            });

            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)

        } catch (err) {
            const status = err.response.status
            if (status === 400 || status === 401){
                setFormError(err.response.data.message)
            }else{
                // display the submission error if the slideIndex is 0
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    }

    const handleSubmit = async () => {
        // clear the form error if any
        setFormError('')

        if(!loading){
            //set loading to true
            setLoading(true)
            
            // call the function to validate inputs
            const inputValid = validateInput()  
            if(inputValid){
                // send data to the backend
                const userSigned = await signUserIn()
                userSigned && navigate('/dashboard')
            } 
        }
    }

    useEffect(()=>{
        /*Change the content of the title bar*/
        document.title = 'Signin'

        // check if there's an access token
        const accessToken = localStorage.getItem("access_token")
        if(accessToken){
            window.history.replaceState(null, null, "/dashboard");
        }
    },[])

    return(
        <div className="registration-auth-form auth-form form">
            {/*signup form*/}
            <div>
                <h2>Account Details</h2>
                {/* email wrapper */}
                <TextInput
                    name="email"                            
                    id="email"
                    label="Email Address"
                    type='email'
                    placeholder='johndoe@gmail.com'
                    inputError={inputErrorMsg.email}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/*password*/}
                <PasswordInput
                    name="password"
                    id="password"
                    label="Password"
                    inputError={inputErrorMsg.password}
                    handleChange={handleChange}
                    isDisabled={loading}
                />
                {/*Remember session forgot password*/}
                <div className="remember-me-forgot-password-wrapper">
                    <div className="remember-me">
                        <input 
                            type="checkbox" 
                            id="RememberMeCheckBox" 
                            className="custom-checkbox" 
                            onChange={handleCheckBoxChange}
                        />
                        <label htmlFor="RememberMeCheckBox">Remember me</label>
                    </div>
                    <div className="forgot-password">
                        <Link to="/reset-password">
                            Forgot password?
                        </Link>
                    </div>
                </div>

                {/*form error*/}
                {
                    formError && (<div className="input-error form-error">
                        {formError}
                    </div>)
                }
                {/* submit button */}
                <button 
                    className={`green-button ${loading && 'green-button-disabled'}`}
                    onClick={handleSubmit}
                >
                    {loading && (<LoadingIndicator/>)}
                        Signin
                </button>
            </div>
        </div>
    )
}

const AltOptionActionComponent = () => <span><Link to="/signup">Signup</Link></span>;

const SigninFormExportTemplate = () => (
        <AuthTemplate
            subText="You are welcome"
            headText="Login to your account"
            FormComponent={SigninForm}
            altOptionNote="Create an account"
            AltOptionActionComponent={AltOptionActionComponent}
        />
    )


export default SigninFormExportTemplate