import { useEffect, useState } from "react"

// style import
import '../../../component_styles/dashboard/dashboard_pane/request_help_pane.css';

// component import
import { 
    constraints,
    CustomTextArea,
    LoadingIndicator,
    TextInput,
} from "../../auth/AuthFormComponents"
import { 
    RequestFormEasyIcon, 
    RequestFormPowerfulIcon,
    RequestFormTruestedIcon, 
} from "../SvgDump"

const RequestPane = () => {

    const [formData, setFormData] = useState({
        'fullname': '',
        'email': '',
        'phone_number':'',
        'address':'',
        'request_type':'',
        'request_description':'',
        'amount_targeted':'',
        'amount_raised':''
    })
    const [formInputError, setFormInputError] = useState({
        'fullname': '',
        'email': '',
        'phone_number':'',
        'address':'',
        'request_type':'',
        'request_description':'',
        'amount_targeted':'',
        'amount_raised':''
    })

    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const value = e.target.value

        setFormData((prev)=>({
            ...prev,
            [e.target.id]: value 
        }))
    }

    const validateInput = () => {
        const {
            fullname,
            email,
            phone_number,
            address,
            request_type,
            request_description,
            amount_targeted,
            amount_raised,
        }  = formData
        const proxyErrorObject = {}
        let aggregateCheck = true
    
        
        // fullname
        if (fullname.length > 2 && constraints.validCharWithSpaceRegex.test(fullname)) {
            proxyErrorObject.fullname = '';  // Clear error
        } else {
            aggregateCheck = false;
            proxyErrorObject.fullname = 'Enter a valid full name.';  // Set error message
        }
    
        // email
        if (constraints.emailRegex.test(email)) {
            proxyErrorObject.email = '';  // Clear error
        } else {  
            aggregateCheck = false;
            proxyErrorObject.email = 'Please enter a valid email address.';  // Set error message
        }
    
        // phone number
        if (phone_number.trim().length < 5) {
            proxyErrorObject.phone_number = 'Please enter a valid phone number.';  // Set error message
            aggregateCheck = false;
        } else {  
            proxyErrorObject.phone_number = '';  // Clear error
        }
    
        // address
        if (address.trim().length < 3) {
            proxyErrorObject.address = 'Please enter a valid address.';  // Set error message
            aggregateCheck = false;
        } else {  
            proxyErrorObject.address = '';  // Clear error
        }
    
        // request type
        if (request_type.trim().length < 2) {
            proxyErrorObject.request_type = 'Please enter a request type.';  // Set error message
            aggregateCheck = false;
        } else {  
            proxyErrorObject.request_type = '';  // Clear error
        }
    
        // request description
        if (request_description.trim().length > 20) {
            proxyErrorObject.request_description = '';  // Clear error
        } else {  
            aggregateCheck = false;
            proxyErrorObject.request_description = 'Please enter a sufficient description.';  // Set error message
        }
    
        // amount targeted
        if (amount_targeted.trim() !== '') {
            proxyErrorObject.amount_targeted = '';  // Clear error
        } else {  
            aggregateCheck = false;
            proxyErrorObject.amount_targeted = 'Please enter a valid target amount.';  // Set error message
        }
    
        // amount targeted
        if (amount_raised.trim() !== '') {
            proxyErrorObject.amount_raised = '';  // Clear error
        } else {  
            aggregateCheck = false;
            proxyErrorObject.amount_raised = 'Please enter a valid amount raised.';  // Set error message
        }
    
        // set the error state
        setFormInputError((prev)=>({
            ...prev,
            ...proxyErrorObject
        }))
    
        setLoading(false)
    
        return aggregateCheck
    }

    const handleSubmit = () => {
        if(!loading){
            // set loading to true
            setLoading(true)

            const testPassed = validateInput()
            if (testPassed){
                let supportTeamMail="support@americannativeheartfoundation.com";
                const link=document.createElement("a")
                link["href"]=`mailto:${supportTeamMail}?subject=Requester info&body=Full name: ${formData.fullname};%0D%0A Email address: ${formData.email};%0D%0A  Phone number: ${formData.phone_number};%0D%0A Address info: ${formData.address};%0D%0A Request type: ${formData.request_type};%0D%0A Case description: ${formData.request_description};%0D%0A Ammount targeted: ${formData.amount_targeted};%0D%0A Amount raised: ${formData.amount_raised};%0D%0A`
                link.click()
            }
        }
    }

    useEffect(()=>{
        /*Change the content of the title bar*/
        document.title = 'Request help'

        /*replace the current history*/
        window.history.replaceState(null, null, "/request-help");
    },[])


    return(
        <div className="request-help-pane">
            <div className="fundraiser-form">
                <div className="fundraiser-main">
                    <h2>Call for Assistance</h2>

                    <div className="requester-info">
                        {/* Here contains requester-info */}
                        <h3>
                            Information of requester
                        </h3>

                        {/* fullname wrapper */}
                        <TextInput
                            name="fullname"
                            id="fullname"
                            label="Fullname"
                            type="text"
                            placeholder="John Doe"
                            inputError={formInputError.fullname}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* email wrapper */}
                        <TextInput
                            name="email"
                            id="email"
                            label="Email Address"
                            type="text"
                            placeholder="johndoe@gmail.com"
                            inputError={formInputError.email}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* Phone number wrapper */}
                        <TextInput
                            name="phone_number"
                            id="phone_number"
                            label="Mobile No."
                            type="text"
                            placeholder="+1 555-123-4567"
                            inputError={formInputError.phone_number}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* address-wrapper */} 
                        <TextInput
                            name="address"
                            id="address"
                            label="Address info"
                            type="text"
                            placeholder="123 Maple Street, Springfield, California, USA"
                            inputError={formInputError.address}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                    </div>
                    
                    {/*Fundraiser info*/}
                    <div className="fundraiser-info">
                        {/* Here contains fundraiser-info */}
                        <h3>
                            Fundraiser information
                        </h3>
                        {/* request type */}
                        <TextInput
                            name="request_type"
                            id="request_type"
                            label="Type of request"
                            type="text"
                            placeholder="health, financial, material"
                            inputError={formInputError.request_type}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* request description */}
                        <CustomTextArea
                            name="request_description"
                            id="request_description"
                            label="Description of case"
                            placeholder="Provide a brief desciption of the case"
                            inputError={formInputError.request_description}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* target amount */}
                        <TextInput
                            name="amount_targeted"
                            id="amount_targeted"
                            label="Amount targeted"
                            type="text"
                            placeholder="$60,000"
                            inputError={formInputError.amount_targeted}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                        {/* amount raised */}
                        <TextInput
                            name="amount_raised"
                            id="amount_raised"
                            label="Amount raised"
                            type="text"
                            placeholder="$39,000"
                            inputError={formInputError.amount_raised}
                            handleChange={handleChange}
                            isDisabled={loading}
                        />
                    </div>
                    {/* notice */}
                    <div className="notice">
                        <h4>Important notice: Please read</h4>
                        <p>
                            To further enhance approval and quality reach-out from our team, we kindly request that you reach 
                            out directly to our dedicated Support Team to get an immediate review. 
                            Our team of experts is available to guide you throughout your journey with American Native Heart Foundation.<br/>
                            <span>
                                Clicking on the Request help Button does that.
                            </span>
                        </p>
                    </div>
                    {/* submit button */}
                    <div className="fundraiser-submit-button-wrapper">
                        <button 
                            style = {
                                loading ? {
                                    backgroundColor: "#ccccccff",
                                    color: "var(--bs-gray-500)",
                                    cursor: "not-allowed",
                                    position: "relative"
                                }:{}
                            }
                            onClick={handleSubmit}
                        >
                            {loading && (<LoadingIndicator/>)}
                            Request help
                        </button>
                    </div>
                </div>
                <div className="fundraiser-aside">
                    <h2>
                        Your easy, powerful, and trusted home for help
                    </h2>
                    <div className="perks">
                        <div>
                            <div>
                                <RequestFormEasyIcon />
                            </div>
                            <div>
                                <span className="perk-head">
                                    Easy
                                </span><br/>
                                <span className="perk-note">
                                    Donate quickly and easily
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <RequestFormPowerfulIcon />
                            </div>
                            <div>
                                <span className="perk-head">Powerful</span><br/>
                                <span className="perk-note">Send help right to the people and causes you care about.</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <RequestFormTruestedIcon />
                            </div>
                            <div>
                                <span className="perk-head">Trusted</span><br/>
                                <span className="perk-note">Your donation is protected by the AmericanNativeHeart Giving Guarantee.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestPane;