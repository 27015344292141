import { useEffect } from "react"
import { flexifyColumns } from "../../../utils"

// style import 
import '../../../../component_styles/dashboard/dashboard_pane/preview_cards.css'

const PreviewImpactCards = ({data}) => {
    useEffect(()=>{
        const lt1280 = matchMedia('(max-width: 1280px)').matches 
        const childMinWidth = lt1280 ? '250px': '350px'
        
        flexifyColumns({
            selector: '.preview-impact-cards',
            gap: 20,
            childMinWidth: childMinWidth
        })
    },[])

    return(
        <div className="preview-impact-cards">
            {
                data.map((data, index)=>(
                    <div key={index} className="preview-impact-card">
                        <div className="preview-impact-card-thumbnail">
                            <img
                                src={data.image_url}
                                alt="card cover"
                            />
                        </div>
                        <p>
                            {
                                data.description.length>400?
                                    `${data.description.slice(0,400)+'...'}` : data.description
                            }
                        </p>
                    </div>
                ))
            }
        </div>
    )
}

export default PreviewImpactCards