import React,{useEffect,useContext} from 'react';

// context import
import {DataContext} from './DataProvider';
import { MenuContext } from './MenuStateProvider';

//style import
import '../../component_styles/dashboard/mainbar.css'

// component import
import { MenuIcon } from './SvgDump';
import PreviewPane from './dashboard_panes/PreviewPane';
import CampaignPane from './dashboard_panes/CampaignPane';
import HistoryPane from './dashboard_panes/HistoryPane';
import SessionExpiredPopup from '../add_ons/SessionExpiredPopup';

// utility import
import { 
    dateOptions
} from '../utils';
import ImpactPane from './dashboard_panes/ImpactPane';
import UserInfoPane from './dashboard_panes/UserInfoPane';
import RequestPane from './dashboard_panes/RequestPane';
import DonatePane from './dashboard_panes/DonatePane';

const MainBar = () => {
    const { data, sessionExpired } = useContext(DataContext);

    const {menuItemState, setMenuOpen} = useContext(MenuContext)

    useEffect(()=>{
        const main = document.querySelector('.mainbar>main');
        const mainBar = document.querySelector('.mainbar');
        const header = document.querySelector('.mainbar>header');
        const gt912 = window.matchMedia("(min-width:912px)").matches; 

        // giving the main element a top margin
        main.style.marginTop = (header.offsetHeight+30) + 'px' 

        if(gt912){
            header.style.width = (mainBar.clientWidth - 40) + 'px'
        }
    },[])

    return(
        <div className="mainbar">
            {/*mainbar header*/}
            <header>
                <div className="menu-icon-wrapper mainbar-menu-btn-wrapper">
                    <button 
                        className="mainbar-menu-btn"
                        onClick={()=>{setMenuOpen(true)}}
                    >
                        <MenuIcon/>
                    </button>
                </div>
                <div className="top-complimentary">
                    <div className="greeting">
                        <span>Hey </span>
                        {
                            data && (
                                <span>
                                    {`${data.first_name}!`}
                                </span>
                            )
                        }
                    </div>
                    <div className="date">{new Date().toLocaleDateString('en-US', dateOptions)}</div>
                </div>
            </header>

            {/*mainbar main content*/}
            <main>
                {/*preview pane*/}
                {menuItemState['dashboard-preview-trigger'] && (<PreviewPane/>)}
                {/*campaign pane*/}
                {menuItemState['fundraising-campaign-trigger'] && (<CampaignPane/>)}
                {/*history pane*/}
                {menuItemState['donation-history-trigger'] && (<HistoryPane/>)}
                {/*impact pane*/}
                {menuItemState['impact-updates-trigger'] && (<ImpactPane/>)}
                {/*event registration*/}
                {menuItemState['event-registration-trigger'] && (<CampaignPane/>)}
                {/*user info*/}
                {menuItemState['account-info-trigger'] && (<UserInfoPane/>)}
                {/*request help*/}
                {menuItemState['request-help-trigger'] && (<RequestPane/>)}
                {/*donate now*/}
                {menuItemState['donate-now-trigger'] && (<DonatePane/>)}
            </main>

            {/*session expired popup*/}
            {sessionExpired && (<SessionExpiredPopup/>)}
        </div>
    )
}

export default MainBar;