import { useContext, useState } from "react"
import { 
    LoadingIndicator, 
    TextInput 
} from "../AuthFormComponents"
import {
    MoveToPreviousSlideIcon 
} from "../SVGDump"
import { ResetPasswordContext } from "./ResetPasswordProvider"
import { baseEndPoint, moveSlides } from "../../utils"


const ResetPasswordSecondSlide = () => {

    const { slideIndex, setSlideIndex } = useContext(ResetPasswordContext)

    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState('')

    const handleChange = (e) => {
        const value = e.target.value

        setCode(value)
    }

    const verifyResetCode = async () => {
        // show the loader
        setLoading(true)

        let funcResponse = false

        try {
            await baseEndPoint.post('/api/verify-password-reset-code/', {
                code: code
            });

            funcResponse =  true

        } catch (err) {
            const status = err && err.response.status
            if (status === 400){
                setFormError(err.response.data.message)
            }else{
                // display the submission error if the slideIndex is 0
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    } 

    const handleSubmit = async () => {
        if(!loading){
            // set loading to true
            setLoading(true)

            // clear the form error if any
            setFormError('')
            
            if(code.trim().length !== 5){
                setCodeError("Please enter a five digit code.")
            }else{
                // empty the input error
                setCodeError('')

                // request code verification
                const resetCodeVerified = await verifyResetCode()

                if(resetCodeVerified){
                    // empty code input
                    setCode('')

                    // move to next slide
                    moveSlides(
                        slideIndex,
                        setSlideIndex,
                        '.reset-password-form',
                        'next'
                    )
                }
            }
        }
    }
    
    const handlePrevious = () => {
        moveSlides(
            slideIndex,
            setSlideIndex,
            '.reset-password-form',
            'previous'
        )

    }


    return(
        <div className="reset-password-form reset-password-second-slide">
            {/* reset password second slide*/}
            <header>
                <button 
                    className="move-to-previous-slide"
                    onClick={handlePrevious}
                >
                    <MoveToPreviousSlideIcon />
                </button>
                    
                <h2>Reset Password</h2>	
            </header>
            {/* note */}
            <p className="verify-email-note">
                An email with a code has been sent to the email address you provided.
            </p>

            {/* verification code input */}
            <TextInput
                name="reset_code"
                id="reset_code"
                type="text"
                label="Enter code"
                placeholder="12345"
                handleChange={handleChange}
                isDisabled={loading}
                inputError={codeError}
            />

            {/*form error*/}
            {formError && (
                <div className="input-error form-error">{formError}</div>
            )}
            <button
                className={`green-button ${loading && 'green-button-disabled'}`}
                onClick={handleSubmit}
            >
                {loading && (<LoadingIndicator/>)}
                Sign up
            </button>
        </div>
    )
}

export default ResetPasswordSecondSlide;