import React from 'react';

import '../../../component_styles/landing/homepage/service_section.css'
import { 
    ChooseSomeOneToHelp,
    RightwardRedArrow,
    SendADonation,
    UseYourPreferredTransferMethod,
} from '../SVGDump';

const ServiceSection = () => (
    <section className="services">
        {/*service section image*/}
        <div className="service-image-wrapper">
            <div className="service-image-inner-wrapper">
                <div className="service-image"></div>
            </div>
        </div>
        <div className="service-text-wrapper">
            <div className="smaller-note-wrapper">
                <div 
                    className="smaller-note"
                >
                    HOW YOU CAN HELP
                </div>
            </div>
            <div 
                className="service-heading heading-text"
            >
                Easy Steps to Changing a Life
            </div>

            {/*service card wrapper*/}
            <div className="service-card-wrapper">
                {/* choose someone to help */}
                <div className="service-card">
                    <div className="service-card-top">
                        <span>
                            <ChooseSomeOneToHelp />
                        </span>
                        <span>Choose someone to help</span>
                    </div>                
                    <div className="service-card-action">
                        <span>
                            <a href="#beneficiaries">
                                Request
                            </a>
                        </span>
                        <span>
                            <RightwardRedArrow />
                        </span>
                    </div>                
                </div>
                {/* use your preferred transfer method*/}
                <div className="service-card">
                    <div className="service-card-top">
                        <span>
                            <UseYourPreferredTransferMethod />
                        </span>
                        <span>Use your preferred transfer method</span>
                    </div>                
                    <div className="service-card-action">
                        <span>
                            <a href="donate.html">
                                Apply
                            </a>
                        </span>
                        <span>
                            <RightwardRedArrow />
                        </span>
                    </div>                
                </div>            
                {/* send a donation */}
                <div className="service-card">
                    <div className="service-card-top">
                        <span>
                            <SendADonation />
                        </span>
                        <span>Send a Donation</span>
                    </div>                
                    <div className="service-card-action">
                        <span>
                            <a href="mailto:support@americannativeheartfoundation.com">
                                Consult
                            </a>
                        </span>
                        <span>
                            <RightwardRedArrow />
                        </span>
                    </div>                
                </div>                
            </div>
        </div>
    </section>
)

export default ServiceSection