import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// icon import
import { 
    MenulistPentagonIcon,
    HistoryIconSVG,
    CampaignIcon,
    ImpactIcon,
    AccountInfoIcon,
    EventRegistrationIcon,
    MenuCloseIcon,
    SidebarHeadNonagon,
    RequestHelpMenuIcon,
    DonateIcon,
} from './SvgDump';

//style import
import "../../component_styles/dashboard/sidebar.css"

// context import
import { DataContext } from './DataProvider';
import { MenuContext } from './MenuStateProvider';

// utility import
import { baseEndPoint } from '../utils';

// top constants
const lt360 = window.matchMedia("(max-width:360px)").matches; 
const lt912 = window.matchMedia("(max-width:912px)").matches; 

const MenuListItem = ({
    Icon, 
    itemText, 
    itemId,
}) => {
    const {switchMenuItemState, menuItemState} = useContext(MenuContext)

    const itemState = menuItemState[itemId]

    return (
        <li 
            className={`${itemState && "active"}`} id={itemId}
            onClick = {()=>{switchMenuItemState(itemId)}}
        >
            <Icon
                fill = {itemState ? "#171717ff":"#7f7f7fff"}
            />
            <span>{itemText}</span>
        </li>
    )
}

const SideBar = () => {

    const location = useLocation()

    const navigate = useNavigate();

    const {data} = useContext(DataContext)
    const {menuOpen, setMenuOpen, switchMenuItemState} = useContext(MenuContext)

    const handleLogout = async () => {
        // get the access_token
        const accessToken = localStorage.getItem("access_token")
        const refreshToken = localStorage.getItem("refresh_token")

        // remove the access and refresh token
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")

        // make a call to the backend to logout
        try {
            const response = await baseEndPoint.post(
                '/api/signout/', 
                {
                    // Payload: Add the refresh_token or other data here
                    refresh_token: refreshToken,  
                },
                {
                    // Headers: Add the Authorization header with the access token
                    headers: {
                        Authorization: `Bearer ${accessToken}`,  // Include the access token in the Authorization header
                    },
                }
            );
            

            console.log(response.data)

        } catch (err) {
            console.log(err.response)
        } finally {
            // replace the current url with the signin
            window.history.replaceState(null, null, "/signin");

            setTimeout((()=>(
                navigate('/signin')
            )),2000)
        }
    }

    useEffect(() => {
        if (location.pathname === '/request-help') {
            switchMenuItemState('request-help-trigger');
        }
        if (location.pathname === '/donate-now') {
            switchMenuItemState('donate-now-trigger');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);  
    
    return (
        <div 
            className="sidebar"
            style = {lt912 ? {left:`${lt360 ? (menuOpen ? "0vw" : "-100vw") : (!menuOpen ? "-360px":"0px")}`}:{left: "0px"}}
        >
            <div className="sidebar-head-et-content">
                <div className="sidebar-head">
                    <div className="sidebar-head-flex1">
                        <Link to="/">
                            <div className="black-box">
                                <SidebarHeadNonagon/>
                            </div>
                        </Link>
                        {data && (
                            <div className="sidebar-head-names">
                                <span>{`${data.first_name} ${data.last_name}`}</span>
                                <span>{data.email}</span>
                            </div>
                        )}
                    </div>
                    {/* menu close button*/}
                    <div className="menu-icon-wrapper sidebar-head-flex2">
                        <button 
                            className="menu-close-btn"
                            onClick = {()=>{setMenuOpen(false)}}
                        >
                            <MenuCloseIcon/>
                        </button>
                    </div>
                </div>
        
                {/* Menu list */}
                <div className="sidebar-content indicate-scrollable-on-hover">
                    <ul>
                        {/*dashboard entry*/}
                        <MenuListItem
                            Icon={MenulistPentagonIcon}
                            itemText="Dashboard"
                            itemId='dashboard-preview-trigger'
                        />
                        {/*Campaign entry*/}
                        <MenuListItem
                            Icon={CampaignIcon}
                            itemText="Fundraising Campaigns"
                            itemId='fundraising-campaign-trigger'
                        />
                        {/*Campaign entry*/}
                        <MenuListItem
                            Icon={DonateIcon}
                            itemText="Donate"
                            itemId='donate-now-trigger'
                        />
                        {/*history entry*/}
                        <MenuListItem
                            Icon={HistoryIconSVG}
                            itemText="Donation History"
                            itemId='donation-history-trigger'
                        />
                        {/*impact entry*/}
                        <MenuListItem
                            Icon={ImpactIcon}
                            itemText='Impact Updates'
                            itemId='impact-updates-trigger'
                        />
                        {/*Account info entry*/}
                        <MenuListItem
                            Icon={AccountInfoIcon}
                            itemText='Account Information'
                            itemId='account-info-trigger'
                        />
                        {/*Request help entry*/}
                        <MenuListItem
                            Icon={RequestHelpMenuIcon}
                            itemText='Request Help'
                            itemId='request-help-trigger'
                        />
                        {/*event registration icon*/}
                        <MenuListItem
                            Icon={EventRegistrationIcon}
                            itemText='Event Registration'
                            itemId='event-registration-trigger'
                        />
                    </ul>
                </div>
            </div>
    
            <div className="sidebar-footer">
                <button 
                    className="logout-trigger"
                    onClick = {handleLogout}
                >
                    Logout
                </button>
            </div>
        </div>
    );
}

export default SideBar;