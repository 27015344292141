import React,{useState, useEffect, createContext} from 'react';

// style import
import '../../component_styles/dashboard/dashboard.css';

// component import
//import { testData } from '../utils';
import { baseEndPoint } from '../utils';

const DataContext = createContext();

const DataProvider = ({children}) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(null)
    const [sessionExpired, setSessionExpired] = useState(false)
    
    
    useEffect(() => {
        // set loading to true
        setLoading(true)
        
        const accessToken = localStorage.getItem("access_token")
        
        const fetchData = async () => {
            try {
                const response = await baseEndPoint.get('/api/user-details/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Add the Authorization header
                    },
                });
                
    
                setData(response.data)
            } catch (err) {
                if(err && err.response.status === 401){
                    // delete the access token from the localstorage
                    localStorage.removeItem("access_token")
                    localStorage.removeItem("refresh_token")
    
                    // set expired to true
                    setSessionExpired(true)
                }
    
            } finally {
                // hide the loader
                setLoading(false)
            }   
        }
        
        if(accessToken){
            fetchData()
        }else{
            // set the session expired to true
            setSessionExpired(true)

            // set loading to false
            setLoading(false)
        }
    
    }, []);
    
    return(
        <DataContext.Provider value={{ loading, data, setData, sessionExpired }}>
            {children}
        </DataContext.Provider>
    )
}

export {
    DataProvider,
    DataContext,
};