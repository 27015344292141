
import '../../../component_styles/landing/homepage/success_stories.css'

const SuccessStoriesSection = () => (
    <section className="success-stories">
        {/*top card*/}
        <div className="success-heading-card-wrapper">
            {/*heading wrapper*/}
            <div className="heading-wrapper">
                <div 
                    className="smaller-note"
                >
                    SUCCESS STORIES
                </div>
                <div 
                    className="success-stories-heading heading-text"
                >
                    By you it's happened
                </div>
                <p>
                    Today, we are filled with immense gratitude and excitement as we 
                    reflect on the incredible journey we've embarked on together. 
                    Your unwavering support has played a pivotal role in making our shared vision a reality
                </p>
            </div>
            {/*success card*/}
            <div className="success-card">
                <img
                    src="https://www.gofundme.com/c/wp-content/uploads/2023/11/YuandMe_Desktop.jpg"
                    alt="success story thumbnail"
                />
                <div className="story" >
                    <div className="story-head">Victoria Y.</div>
                    <div className="story-sub">
                        When fires damaged Yu & Me Books, 6.8k people helped them reopen.
                    </div>
                </div>
            </div>
        </div>
        {/*auxiliary cards*/}
        <div className="success-stories-card-wrapper">
            <div className="success-card">
                <img
                    src="https://www.gofundme.com/c/wp-content/uploads/2023/11/item-7@2x.jpg"
                    alt="success story thumbnail"
                />
                <div className="story">
                    <div className="story-head">County Tipperary, Ireland   •   January 12</div>
                    <div className="story-sub">When Cara broke national math records, donors helped her raise funds for Autism awareness and services. </div>
                </div>
            </div>
            <div className="success-card">
                <img
                    src="https://www.gofundme.com/c/wp-content/uploads/2023/11/item-3@2x.jpg"
                    alt="success story thumbnail"
                />
                <div className="story">
                    <div className="story-head">Kirkwood, MO   •   February 23</div>
                    <div className="story-sub">When Delayne gave her dad her kidney, donors helped them pay off medical bills.</div>
                </div>
            </div>
            <div className="success-card">
                <img
                    src="https://www.gofundme.com/c/wp-content/uploads/2023/11/item-9@2x.jpg"
                    alt="success story thumbnail"
                />
                <div className="story" >
                    <div className="story-head">Rock Island, IL   •   August 27</div>
                    <div className="story-sub">When Cecilia needed a new wheelchair, hundreds of people chipped in.</div>
                </div>
            </div>
            <div className="success-card">
                <img
                    src="https://www.gofundme.com/c/wp-content/uploads/2023/11/item-12@2x.jpg"
                    alt="success story thumbnail"
                />
                <div className="story">
                    <div className="story-head">Tustin, CA   •   April 6</div>
                    <div className="story-sub">When Emily turned her Taylor Swift ticket into a movement, donors joined her cause to fight malaria.</div>
                </div>
            </div>
        </div>
    </section>
)

export default SuccessStoriesSection